import React, { Component } from "react";
import validator from "validator";
import {
  Backdrop,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import {
  CssTextField,
  StyledButton,
  StyledCircularProgress,
} from "../styles/StyledComponents";
import { primary } from "../styles/colors";
import { CrossIcon, TopArrow, BottomArrow } from "../styles/Icons";
import { CheckCircle } from "@material-ui/icons";
import SnackbarComponent from "./SnackbarComponent";
import { trimEnd } from "lodash";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs

import MultipleSelectBar from "./MultipleSelectBar";
import { EndPoints } from "../utils/EndPoints";
import { StopScroll } from "../Regex";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

const styles = {
  withDivider50: { width: "calc(25% - 20px)", margin: "10px" },
  withDivider502: { width: "calc(50% - 20px)", margin: "10px" },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  bottomHeading: {
    margin: "0 10px 0 0",
    width: "390px",
  },
};
export class CreateDialogForSurvayReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "paper",
      dataAdded: false,
      loading: false,
      comment: "",
      error: "",
      success: "",
      submittedData: {},
      addFieldsToAdd: false,
      errorMsg: {
        comment: false,
        file: false,
      },
    };
  }
  reset = () => {
    const { submittedData, inputArray ,calenderOpen} = this.state;

    const { selectedList } = this.props;
    const noData = [];
    const errorMsg = {};
    const newSubmittedData = {};
    inputArray.map((singleInput) => {
      if (submittedData[singleInput.name]) {
        errorMsg[singleInput.name] = false;
        newSubmittedData[singleInput.name] = "";
        return noData.push(singleInput.name);
      } else {
        return (errorMsg[singleInput.name] = false);
      }
    });
    if (noData.length === 0) {
      this.setState({ error: "Nothing to reset !", errorMsg });
    } else {
      const newSubmittedData = {};
      inputArray.map((singleInput) => {
        if (selectedList[singleInput.name]) {
          errorMsg[singleInput.name] = false;
          if (singleInput.type === "Month") {
            newSubmittedData[singleInput.name] =
              selectedList[`${singleInput.name}Month`];
          } else if (singleInput.type === "checkBox") {
            return (newSubmittedData[singleInput.name] =
              selectedList[singleInput.name] === "Paid" ? true : false);
          } else {
            newSubmittedData[singleInput.name] = selectedList[singleInput.name];
            newSubmittedData[`${singleInput.name}Id`] = selectedList[
              `${singleInput.name}Id`
            ]
              ? selectedList[`${singleInput.name}Id`]
              : "";

            return newSubmittedData;
          }
        } else if (singleInput.defaultValue) {
          errorMsg[singleInput.name] = false;
          newSubmittedData[singleInput.name] =
            singleInput.type === "checkBox" &&
            singleInput.defaultValue.value === false
              ? false
              : singleInput.defaultValue.value
              ? singleInput.defaultValue.value
              : "";

          newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue
            .id
            ? singleInput.defaultValue.id
            : "";

          return newSubmittedData;
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] = new Date();
        } else if (singleInput.type === "checkBox") {
          newSubmittedData[singleInput.name] = false;
        } else {
          errorMsg[singleInput.name] = false;
          return (newSubmittedData[singleInput.name] = "");
        }
      });
      this.setState(
        {
          dataAdded: false,
          comment: "",
          submittedData: newSubmittedData,
          errorMsg,
        },
        () => {
          this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };

  setInitialValues = (next) => {
    const { selectedList, inputArray } = next;
    const newSubmittedData = {};
    const errorMsg = this.state.errorMsg;
    inputArray.map((singleInput) => {
      if (selectedList[singleInput.name]) {
        errorMsg[singleInput.name] = false;
        if (singleInput.type === "checkBox") {
          return (newSubmittedData[singleInput.name] =
            selectedList[singleInput.name] === "Paid" ? true : false);
        } else if (singleInput.type === "Month") {
          newSubmittedData[singleInput.name] =
            selectedList[`${singleInput.name}Month`];
        } else {
          newSubmittedData[singleInput.name] = selectedList[singleInput.name];
          newSubmittedData[`${singleInput.name}Id`] = selectedList[
            `${singleInput.name}Id`
          ]
            ? selectedList[`${singleInput.name}Id`]
            : "";

          return newSubmittedData;
        }
      } else if (singleInput.defaultValue) {
        errorMsg[singleInput.name] = false;
        newSubmittedData[singleInput.name] =
          singleInput.type === "checkBox" &&
          singleInput.defaultValue.value === false
            ? false
            : singleInput.defaultValue.value
            ? singleInput.defaultValue.value
            : "";

        newSubmittedData[`${singleInput.name}Id`] = singleInput.defaultValue.id
          ? singleInput.defaultValue.id
          : "";

        return newSubmittedData;
      } else if (singleInput.type === "Month") {
        newSubmittedData[singleInput.name] = new Date();
      } else if (singleInput.type === "checkBox") {
        newSubmittedData[singleInput.name] = false;
      } else {
        errorMsg[singleInput.name] = false;
        return (newSubmittedData[singleInput.name] = "");
      }
    });
    // if (selectedList) {
    //   this.setState({ submittedData: newSubmittedData });
    // } else {
    this.setState({
      submittedData: newSubmittedData,
      inputArray: next.inputArray,
      addFieldsToAdd: next.addFieldsToAdd,
      tableValueDetailArray: next.tableValueDetailArray,
    });
    // }
  };
  UNSAFE_componentWillReceiveProps(next) {
    // this.setInitialValues(next);
    this.setState({
      inputArray: next.inputArray,
      addFieldsToAdd: next.addFieldsToAdd,
      tableValueDetailArray: next.tableValueDetailArray,
    });
  }
  componentWillMount() {
    this.setInitialValues(this.props);
  }

  handleUpdate = (e) => {
    this.setState({ cancel: false });
    e.preventDefault();
    let file = {};

    if (e.target.files[0]) {
      const kb = (e.target.files[0].size / 1024).toFixed(2);
      const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

      if (kb <= 1024) {
        const size = `${kb}${" "}kb`;
        file.mainSize = size;
        this.setState({ size });
      }
      if (1024 < kb) {
        const size = `${mb}${" "}mb`;
        file.mainSize = size;
        this.setState({ size });
      }

      file.img = e.target.files[0];
      file.url = URL.createObjectURL(e.target.files[0]);
      var errorMsg = this.state.errorMsg;
      errorMsg.file = false;
      this.setState({ file, errorMsg });
    }
  };
  render() {
    const {
      scroll,
      loading,
      dataAdded,
      errorMsg,
      error,
      success,
      submittedData,
      inputArray,
      tableValueDetailArray,
      addFieldsToAdd,calenderOpen
    } = this.state;
    const {
      module,
      selectedList,
      editList,
      heightNew,
      maxHeight,
      widthNew,
      textFieldWidth,
      SurveyCompanyList,
    } = this.props;
    console.log(SurveyCompanyList, "listtttttttt");
    return (
      <Dialog
        open={true}
        onClose={() => {
          if (dataAdded) {
            this.props.fetchAgain();
          }
          this.props.handleClose();
        }}
        scroll={scroll}
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children
              ? ReactDOM.findDOMNode(e).children[2].children[0]
              : null;
            target.style.borderRadius = 0;
            target.style.width = widthNew ? widthNew : "90%";
            target.style.maxWidth = widthNew ? widthNew : "100%";
            target.style.height = heightNew ? heightNew : "calc(100vh - 40px)";
            target.style.minHeight = heightNew
              ? heightNew
              : "calc(100vh - 40px)";

            target.style.maxHeight = maxHeight
              ? maxHeight
              : "calc(100vh - 40px)";
            target.style.margin = "20px 0";
            target.style.position = "relative";
            target.style.overflow = "hidden";
          } catch (e) {}
        }}
      >
        {loading ? (
          <Backdrop
            style={{ zIndex: 1, color: "#fff", position: "absolute" }}
            open={loading}
          >
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 2000}
            success={success}
            snackbarClose={() => {
              if (success === `${module} Edited`) {
                this.setState({ error: "", success: "" }, () => {
                  this.props.handleClose();
                });
              } else {
                this.setState({ error: "", success: "" });
              }
            }}
          />
        ) : (
          ""
        )}

        <DialogActions
          // classname="d-flex justifySB"
          style={{ background: primary, color: "white", padding: "10px 24px" }}
        >
          <h3 style={styles.heading}>
            {editList ? "Edit " : "Add "} {trimEnd(module, "s")}
          </h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton
              style={{ padding: "5px", borderRadius: "5px" }}
              onClick={() => {
                if (dataAdded) {
                  this.props.fetchAgain();
                }
                this.props.handleClose();
              }}
            >
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <DialogContent
          dividers={scroll === "paper"}
          className="dashedDividerBottom"
          style={{ height: "100%" }}
        >
          {dataAdded ? (
            <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
              <CheckCircle
                style={{ width: "100px", height: "100px", color: "#1a9520" }}
              />
              <span
                className="heading"
                style={{ color: "#1a9520", margin: "10px 0 0 0" }}
              >
                {dataAdded}
              </span>
            </div>
          ) : (
            <div className="height-100 width-100 d-flex">
              <div className="heightFC width-100 d-flex flexWrap">
                {inputArray &&
                  inputArray.length > 0 &&
                  inputArray.map((singleInput, i) => (
                    <>
                      {singleInput.type === "textField" ? (
                        <CssTextField
                          onFocus={StopScroll}
                          autoFocus={singleInput.autoFocus ? true : false}
                          className={`${
                            singleInput.upperCase
                              ? "textTU"
                              : singleInput.capitalize
                              ? "textC"
                              : ""
                          } ${
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }`}
                          required={
                            singleInput.not_required
                              ? !singleInput.not_required
                              : true
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="off"
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          InputProps={{
                            inputProps: {
                              min: singleInput.min && singleInput.min,
                            },
                            endAdornment: singleInput.endAdornment
                              ? // ? submittedData[singleInput.name]
                                singleInput.endAdornment
                              : // : ''
                                "",
                            startAdornment: singleInput.startAdornment
                              ? // ? submittedData[singleInput.name]
                                singleInput.startAdornment
                              : // : ''
                                "",
                          }}
                          style={
                            textFieldWidth
                              ? styles.withDivider502
                              : styles.withDivider50
                          }
                          id="outlined-basic"
                          label={
                            singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                          }
                          type={singleInput.dataType}
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : ""
                          }
                          name={singleInput.name}
                          onKeyDown={(evt) => {
                            if (singleInput.only_number === true) {
                              evt.key === "." && evt.preventDefault();
                            }
                            if (singleInput.dataType === "number") {
                              evt.key === "e" && evt.preventDefault();

                              evt.key === "E" && evt.preventDefault();
                              evt.key === "-" && evt.preventDefault();
                              evt.keyCode === 38 && evt.preventDefault();
                              evt.keyCode === 40 && evt.preventDefault();
                            }
                          }}
                          onInput={(e) => {
                            // if (singleInput.only_number === true) {
                            //   e.target.value = e.target.value.replace(/[^\w]|_/g, "").toLowerCase();
                            // }
                            if (singleInput.onInput === "phone_number") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 10)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                              //   : "";
                            }
                            if (singleInput.api === "account_no") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 20)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 20)
                              //   : "";
                            }
                            if (singleInput.api === "swift_code") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 12)
                                : "";
                              //  e.target.value
                              //   ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                              //   : "";
                            }
                            if (singleInput.onInput === "pincode") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 6)
                                : "";
                            }
                            if (singleInput.onInput === "gst_in") {
                              e.target.value = e.target.value
                                ? e.target.value.toString().slice(0, 15)
                                : "";
                            }
                          }}
                          onChange={(event) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[event.target.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[event.target.name] =
                              event.target.value && singleInput.upperCase
                                ? event.target.value.toUpperCase()
                                : event.target.value;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(submittedData)
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                          variant="outlined"
                          // className="textC"
                        />
                      ) : singleInput.type === "select" ? (
                        <CssTextField
                          className={
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }
                          required={true}
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          autoComplete="off"
                          value={submittedData[singleInput.name]}
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          style={
                            textFieldWidth
                              ? styles.withDivider502
                              : styles.withDivider50
                          }
                          id="outlined-select-currency-native"
                          select
                          label={
                            singleInput.name.charAt(0).toUpperCase() +
                            singleInput.name.slice(1)
                          }
                          SelectProps={{ disableunderline: "true" }}
                          variant="outlined"
                          onChange={(e) => {
                            const id = `${singleInput.name}Id`;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = e.target.value;
                            var errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            submittedData[id] = e.currentTarget.id;
                            if (singleInput.onChange) {
                              singleInput
                                .onChange(submittedData)
                                .then((submittedData) => {
                                  this.setState({
                                    submittedData,
                                    errorMsg,
                                  });
                                });
                            } else {
                              this.setState({
                                submittedData,
                                errorMsg,
                              });
                            }
                          }}
                        >
                          {singleInput &&
                            singleInput.array &&
                            singleInput.array.length > 0 &&
                            singleInput.array.map((option) => (
                              <MenuItem
                                key={option[singleInput.value]}
                                value={option[singleInput.value]}
                                id={
                                  option.id
                                    ? option.id
                                    : option[singleInput.value]
                                }
                              >
                                {singleInput.endAdornment
                                  ? `${option[singleInput.value]} ${
                                      singleInput.endAdornment
                                    }`
                                  : // : ''
                                    option[singleInput.value]}
                              </MenuItem>
                            ))}
                        </CssTextField>
                      ) : singleInput.type === "Month" ? (
                        <MuiPickersUtilsProvider
                        libInstance={moment()}
                        utils={DateFnsUtils}
                        key={`${i}input`}
                      >
                        <CssKeyboardDatePicker
                          className={
                            singleInput.disabled &&
                            singleInput.disabled === true
                              ? "disabled"
                              : ""
                          }
                          disabled={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          variant="inline"
                          onOpen={() => {
                            this.setState({ [`calenderOpen${i}`]: true });
                          }}
                          onClose={() => {
                            this.setState({ [`calenderOpen${i}`]: false });
                          }}
                          onKeyUp={(ev) => {
                            ev.preventDefault();
                          }}
                          onKeyDown={(ev) => {
                            ev.preventDefault();
                          }}
                          
                          style={styles.withDivider50}
                          
                          // minDate={"2022-01-22"}
                          error={false}
                          invalidLabel=""
                          onKeyPress={(ev) => {
                            ev.preventDefault();
                          }}
                          allowKeyboardControl={false}
                          keyboardIcon={
                            this.state[`calenderOpen${i}`] ? (
                              <TopArrow />
                            ) : (
                              <BottomArrow />
                            )
                          }
                          //
                          autoOk
                          inputVariant="outlined"
                          label={singleInput.name}
                          format="do MMM, yyyy"
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : new Date()
                          }
                          views={singleInput.views && singleInput.views} //['year', 'month']}
                          InputAdornmentProps={{ position: "end" }}
                          onChange={(newValue) => {
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = newValue;

                            this.setState({
                              submittedData,
                            });
                          }}
                        />
                      </MuiPickersUtilsProvider>
                        // <MuiPickersUtilsProvider
                        //   libInstance={moment()}
                        //   utils={DateFnsUtils}
                        //   key={`${i}input`}
                        // >
                        //   <CssKeyboardDatePicker

                        //     className={
                        //       singleInput.disabled &&
                        //       singleInput.disabled === true
                        //         ? "disabled"
                        //         : ""
                        //     }
                        //     variant="inline"
                        //     onOpen={() => {
                        //       this.setState({ [`calenderOpen${i}`]: true });
                        //     }}
                        //     onClose={() => {
                        //       this.setState({ [`calenderOpen${i}`]: false });
                        //     }}
                        //     
                        //     style={
                        //       textFieldWidth
                        //         ? styles.withDivider502
                        //         : styles.withDivider50
                        //     }
                        //     
                        //     // minDate={"2022-01-22"}
                        //     error={false}
                        //     invalidLabel=""
                        //     onKeyPress={(ev) => {
                        //       ev.preventDefault();
                        //     }}
                            
                        //     allowKeyboardControl={false}
                        //     keyboardIcon={
                        //       this.state[`calenderOpen${i}`] ? (
                        //         <TopArrow />
                        //       ) : (
                        //         <BottomArrow />
                        //       )
                        //     }
                        //     // clearable
                        //     autoOk
                        //     inputVariant="outlined"
                        //     label={singleInput.name}
                        //     format="do MMM, yyyy"
                        //     value={
                        //       submittedData[singleInput.name]
                        //         ? submittedData[singleInput.name]
                        //         : new Date()
                        //     }
                        //     views={singleInput.views && singleInput.views} //['year', 'month']}
                        //     InputAdornmentProps={{ position: "end" }}
                        //     onChange={(newValue) => {
                        //       const submittedData = this.state.submittedData;
                        //       submittedData[singleInput.name] = newValue;

                        //       this.setState({
                        //         submittedData,
                        //       });
                        //     }}
                        //   />
                        // </MuiPickersUtilsProvider>
                      ) : singleInput.type === "multiInput" ? (
                        <MultipleSelectBar
                          onlyDisable={
                            singleInput.disabled ? singleInput.disabled : false
                          }
                          width={
                            textFieldWidth ? textFieldWidth : "calc(25% - 20px)"
                          }
                          margin={"10px"}
                          value={
                            submittedData[singleInput.name]
                              ? submittedData[singleInput.name]
                              : []
                          }
                          array={
                            singleInput &&
                            singleInput.array &&
                            singleInput.array.length > 0
                              ? singleInput.array
                              : []
                          }
                          error={errorMsg[singleInput.name] ? true : false}
                          helperText={
                            errorMsg[singleInput.name]
                              ? errorMsg[singleInput.name]
                              : false
                          }
                          title={singleInput.value}
                          selectedArr={(val) => {
                            const errorMsg = this.state.errorMsg;
                            errorMsg[singleInput.name] = false;
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] = val;

                            submittedData[`${singleInput.name}Id`] =
                              val &&
                              val.map((sA) => {
                                return sA.id;
                              });

                            this.setState({ errorMsg, submittedData });
                          }}
                        />
                      ) : singleInput.type === "checkBox" ? (
                        <FormControlLabel
                          style={{ margin: "0 10px" }}
                          onChange={(newValue) => {
                            const submittedData = this.state.submittedData;
                            submittedData[singleInput.name] =
                              submittedData[singleInput.name] === true ||
                              submittedData[singleInput.name] === "Paid"
                                ? false
                                : true;

                            this.setState({
                              submittedData,
                            });
                          }}
                          control={
                            <Checkbox
                              checked={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : false
                              }
                            />
                          }
                          label={singleInput.name}
                        />
                      ) : singleInput.type === "toggle" ? (
                        <FormControlLabel
                          className="d-flex justifyFE"
                          style={{ margin: "5px", width: "100%" }}
                          control={
                            <Switch
                              disabled={editList ? false : true}
                              checked={
                                submittedData[singleInput.name]
                                  ? submittedData[singleInput.name]
                                  : false
                              }
                              onChange={(newValue) => {
                                const submittedData = this.state.submittedData;
                                submittedData[singleInput.name] =
                                  submittedData[singleInput.name] === true
                                    ? false
                                    : true;

                                this.setState({
                                  submittedData,
                                });
                              }}
                            />
                          }
                          label={
                            submittedData[singleInput.name]
                              ? "Active"
                              : "Inactive"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ))}{" "}
                <div className="width-100 d-flex heightFC">
                  {this.props.tableData
                    ? this.props.tableData(editList, submittedData)
                    : ""}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="d-flex justifyFE"
            style={{ width: "calc(100% - 20px)" }}
          >
            {editList && dataAdded ? (
              ""
            ) : !editList && dataAdded ? (
              <StyledButton
                onClick={() => {
                  this.reset();
                  if (editList) {
                  } else {
                    this.props.reset();
                  }
                }}
                name={"Add Another"}
                withBg={true}
                background={"#1a9520"}
                width="120px"
              />
            ) : (
              //  editList ? (
              //   <StyledButton
              //     onClick={() => {
              //       const { submittedData } = this.state;
              //       let submitData = new FormData();
              //       const correctData = [];
              //       inputArray.map((singleInput) => {
              //         if (
              //           (singleInput.type === "checkBox" &&
              //             (submittedData[singleInput.name] === false ||
              //               submittedData[singleInput.name] === true)) ||
              //           submittedData[singleInput.name]
              //         ) {
              //           correctData.push(singleInput.name);
              //           return submitData.append(
              //             singleInput.name,
              //             submittedData[singleInput.name]
              //           );
              //         } else {
              //           var errorMsg = this.state.errorMsg;
              //           errorMsg[
              //             singleInput.name
              //           ] = `Please add ${singleInput.name} !`;
              //           return this.setState({ errorMsg });
              //         }
              //       });
              //       if (inputArray.length === correctData.length) {
              //         this.setState({ loading: true });
              //         this.props
              //           .postData(
              //             "patch",
              //            this. props.editApi,
              //             selectedList.id,
              //             "editdata",
              //             submittedData
              //           )
              //           .then((res) => {
              //             if (res.error) {
              //               this.setState({
              //                 error: res.data,
              //                 loading: false,
              //               });
              //             } else {
              //               this.setState({
              //                 dataAdded: `Successfully edited !`,
              //                 loading: false,
              //               });
              //             }
              //           });
              //       } else {
              //       }
              //     }}
              //     name={"Edit"}
              //     withBg={true}
              //     background={"#1a9520"}
              //     width="120px"
              //   />
              // ) :
              <>
                <StyledButton
                  onClick={() => {
                    this.reset();
                    if (editList) {
                    } else {
                      this.props.reset();
                    }
                  }}
                  name={editList ? "Reset to Default" : "Reset"}
                  // withBg={true}
                  // background={primary}
                  // width="120px"
                  margin="0 10px 0 0"
                />
                <StyledButton
                  onClick={() => {
                    const { submittedData } = this.state;
                    let submitData = new FormData();
                    const correctData = [];
                    inputArray
                      .filter((sA) => sA.api)
                      .map((singleInput) => {
                        if (
                          (singleInput.type === "checkBox" &&
                            (submittedData[singleInput.name] === false ||
                              submittedData[singleInput.name] === true)) ||
                          submittedData[singleInput.name]
                        ) {
                          if (
                            (singleInput.onInput === "phone_number" &&
                              validator.isMobilePhone(
                                submittedData[singleInput.name]
                              )) ||
                            singleInput.onInput !== "phone_number"
                          ) {
                            if (singleInput.type === "Month") {
                              correctData.push(singleInput.name);
                              return (
                                singleInput.api &&
                                submitData.append(
                                  singleInput.api,
                                  moment(
                                    submittedData[singleInput.name]
                                  ).format("YYYY-MM-DD")
                                )
                              );
                            } else {
                              correctData.push(singleInput.name);
                              return (
                                singleInput.api &&
                                submitData.append(
                                  singleInput.api,
                                  submittedData[`${singleInput.name}Id`]
                                    ? submittedData[`${singleInput.name}Id`]
                                    : singleInput.dataType === "number"
                                    ? Number(submittedData[singleInput.name])
                                    : submittedData[singleInput.name]
                                )
                              );
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg[
                              singleInput.name
                            ] = `Invalid ${singleInput.name} !`;
                            return this.setState({ errorMsg });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg[
                            singleInput.name
                          ] = `Please add ${singleInput.name} !`;
                          return this.setState({ errorMsg });
                        }
                      });

                    if (
                      inputArray &&
                      inputArray.length &&
                      inputArray.filter((sA) => sA.api).length ===
                        correctData.length
                    ) {
                      this.setState({ loading: true });
                      if (addFieldsToAdd && addFieldsToAdd.length > 0) {
                        addFieldsToAdd.map((sField) => {
                          return (
                            sField.name &&
                            submitData.set(sField.name, sField.value)
                          );
                        });
                      }

                      const newData = {};
                      if (
                        tableValueDetailArray &&
                        tableValueDetailArray.length > 0
                      ) {
                        const survayItems = [];
                        console.log(tableValueDetailArray, "aaaaaaaaaaa");
                        tableValueDetailArray.map((sItem) => {
                          const newObj = {};
                          // "Brand","Item Category", "Item Type","Moisture","Broken","Damaged/Discoloured","Chalky","Length","Paddy",
                          //       "Foriegn Matter","Double Polish","REMARK","Surveyor"
                          newObj["po_item"] = sItem["po_item"];
                          newObj["sampling_date"] = moment(
                            submittedData["Survey Date"]
                          ).format("YYYY-MM-DD");
                          newObj["brand"] = sItem["Brandid"];
                          newObj["miller"] =
                            submittedData["MillerName & AddressId"] &&
                            submittedData["MillerName & AddressId"];
                          newObj["quantity"] = 21;
                          newObj["moisture"] = sItem["Moisture"];
                          newObj["damaged"] = sItem["Damaged/Discoloured"];
                          newObj["chalky"] = sItem["Chalky"];
                          newObj["green_grain"] =  sItem["Green Grain"];
                          newObj["average_grain_length"] =  sItem["Average Grain Length"];
                          newObj["whole_red_grain"] =  sItem["Whole Red Grain"];

                          newObj["length"] = sItem["Length"];
                          newObj["paddy"] = sItem["Paddy"];
                          newObj["foreign_material"] = sItem["Foreign Matter"];
                          newObj["double_polish"] = sItem["Double Polish"];
                          newObj["remark"] = sItem["REMARK"];
                          newObj["survey_company"] = sItem["Survey Companyid"];
                          newObj["surveyor"] = sItem["Surveyorid"];
                          // newObj["surveyor"] = sItem["Surveyorid"];
                          newObj["broken"] = sItem["Broken"];

                          if (editList) {
                            newObj.survey_report = selectedList.id;
                            newObj["id"] = sItem["id"];
                          }
                          survayItems.push(newObj);
                          return sItem;
                        });
                        newData.survey_report_items = survayItems;

                        newData.survey_report_items = survayItems;
                        newData.survey_report = {
                          sampling_date: moment(
                            submittedData["Survey Date"]
                          ).format("YYYY-MM-DD"),
                          purchase_order: this.props.singlelistView
                            ? this.props.singlelistView.id
                            : "",
                        };

                        this.props
                          .postData(
                            editList ? "put" : "post",

                            editList ? this.props.editApi : this.props.postApi,
                            null,
                            "postdata",
                            editList ? survayItems : newData
                          )
                          .then((res) => {
                            if (res.error) {
                              this.setState({
                                error:
                                  typeof res.data === "string"
                                    ? res.data
                                    : res.data.length > 0
                                    ? res.data
                                        .map((s) => {
                                          return `${
                                            s.field ? `${s.field} :` : ""
                                          } ${
                                            typeof s.message === "string"
                                              ? s.message
                                              : s.message.length > 0 &&
                                                s.message[0] &&
                                                s.message[0]
                                          }`;
                                        })
                                        .join(" ,")
                                    : res.data,
                                loading: false,
                              });
                            } else {
                              if (this.props.onEditUpdate) {
                                this.props.onEditUpdate(res);
                              }
                              this.setState(
                                {
                                  success: editList ? `${module} Edited` : "",
                                  dataAdded: editList
                                    ? `${module} Edited`
                                    : `New ${module} Added`,
                                  loading: false,
                                },
                                () => {
                                  if (editList) {
                                    this.props.fetchAgain();
                                  }
                                }
                              );
                            }
                          });
                      } else {
                        this.props.settError();
                        this.setState({
                          loading: false,
                        });
                      }
                    } else {
                    }
                  }}
                  name={editList ? "Update" : "Submit"}
                  withBg={true}
                  background={"#1a9520"}
                  width="120px"
                />
              </>
            )}
          </div>{" "}
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateDialogForSurvayReport;
