export const EndPoints = {
  Packagings: "masters/packaging/list",
  ContractTypes: "masters/contract-type/list",
  PaymentTerms: "masters/payment-terms/list",
  ItemTypes: "masters/item-type/list",
  ItemCategories: "masters/item-category/list",
  SurveyCompanys: "masters/survey-company/list",
  Clients: "masters/clients/list",
  Millers: "masters/miller/list",
  Brokers: "masters/broker/list",
  Transporters: "masters/transporter/list",
  Ports: "masters/port/list",
  PurchaseOrders: "dispatch/purchase-order-lite/list/",
  Homes:"reports/purchase-orders",
  SalesOrders: "dispatch/sales-order-lite/list/",
  Shippers: "masters/shipper/list",
  Beneficiarys: "masters/benificiary/list",
  HsNs: "masters/hsn/list",
  BrandsManagements: "masters/brand/list",
  UsersAndPermissions: "users/list",
  RiceBagSellers: "masters/ricebagseller/list",
  Notifys: "masters/notify/list",
  Currencys: "masters/currency/list",
  SpecificationTypes: "masters/specification-type/list",
  SpecificationConditions: "masters/specification-condition/list",
  Incoterms: "masters/inco-term/list",
  SOpaymentTerms: "masters/so-payment-term/list",
  ShippingTerms: "masters/shipping-term/list",
  Precarriers: "masters/precarrier/list",
  Policys: "masters/so-policy/list",
};
