import React, { useRef } from "react";

import "../PurchaseOrders/pdf.css";
import { PDFExport } from "@progress/kendo-react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { filterData } from "../../Regex";
import parse from "html-react-parser";
import { CssButton } from "../PurchaseOrders/PurchaseOrdersComponent";
import moment from "moment";
import { boldCss, HDivider } from "../PurchaseOrders/PurchaseOrderPdf";
import { CompanyFullName } from "../../Defaults";
import SvgPreview from "../PurchaseOrders/SvgPreview";

function SalesOrderPdf(props) {
  const {
    singlelistView,
    tableList,
    ClientList,
    CurrencyList,
    PortsList,
    SoPaymentList,
    addendum,
    preview,
    deliveryTerm,
    ValueinWords,
    specifiationTypes,
  } = props;
  const contentArea = useRef(null);
  const getSpecificationName = (sp) => {
    switch (sp) {
      case "equal_to":
        return "only";

      case "not_equal_to":
        return "not";

      case "greater_than":
        return "above";

      case "greater_than_or_equal_to":
        return "min";

      case "less_than":
        return "below";

      case "less_than_or_equal_to":
        return "max";
    }
  };
  var converter = require("number-to-words");

  const handleExportWithFunction = (event) => {
    if (contentArea.current) {
      contentArea.current.save();
    }
  };

  const buyerDetails = filterData(
    ClientList,
    "id",
    singlelistView.buyer,
    "onlyOne"
  )
    ? filterData(ClientList, "id", singlelistView.buyer, "onlyOne")
    : "";
  const file = {
    name: "Sales Order No.",
    value: singlelistView && singlelistView.so_number,
    date: singlelistView.contract_date,
    buyer: buyerDetails.client_name,
    buyerAddress: buyerDetails.address,
    buyerCity: buyerDetails.city,
    buyerState: buyerDetails.state,
    email: buyerDetails.email,
    totalQuantity: singlelistView.total_quantity,
    unit: singlelistView.unit,
    terms: singlelistView.terms,
  };
  const PageTemplate = (props) => {
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "15px",
            right: "15px",
            height: "90px",
            width: "calc(100% - 30px)",
            fontFamily: "Arial",
          }}
        >
          <div
            style={{
              width: "100%",
              borderBottom: "1.5px solid rgba(0, 0, 0, 2.25)",
            }}
          >
            <div
              style={{
                // height: "53.18px",
                padding: "5px 0",
                width: "calc(100% - 40px)",
                display: "flex",
                textTransform: "initial !important",
                margin: "0 10px",
              }}
            >
              <SvgPreview
                svg={"/SmileBots-log.png"}
                sx={{
                  height: "40px",
                  width: "60px",
                }}
              />
               <div
      style={{
        width: "calc(100% - 120px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRIGHT:"60px",
        textAlign:"justify"
      }}
    >
      <h2
        style={{
          ...boldCss,
          // textAlign: "end",
          textTransform: "uppercase",
          margin: "0 2px 0 0",
          fontSize: "0.9rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        textAlign:"justify"
        }}
      >
        {CompanyFullName}
      </h2>
      <div
        style={{
          fontSize: "0.8rem",
          fontWeight: "600",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        textAlign:"justify"
        }}
      >
        CIN No: U14200CT2007PTC020290
      </div>
    </div>
            </div>
          </div>

          <div
            style={{
              width: "calc(100% - 25px)",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              textDecoration: "underline",
              fontSize: "0.6rem",
              marginLeft: "25px",
            }}
          >
            {`Contract no: ${file.value}`} <br />
            {`Dated: ${
              file.date ? moment(file.date).format("DD.MM.YYYY") : ""
            }`}
          </div>
          <br />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "15px",
            right: "15px",
            height: "40px",
            width: "calc(100% - 30px)",
            fontFamily: "Arial",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "0.6rem",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "0.5rem",
              }}
            >{`Page:${props.pageNum}/${props.totalPages}`}</div>
            {HDivider}
            <div style={{ width: "100%", fontWeight: "bold" }}>
              Registered Off : Behind Samrat Talkies, Civil Station Road, Raipur
              -492001,(C.G.) Ph:+91 771 2887453, 2524245
            </div>
            <div
              style={{
                width: "100%",
                // fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                Fax: +91 771 4032240, Email : sainath@srisainath.com Web:
                <span style={{ ...boldCss }}>www.srisainath.com</span>
              </div>
              <div>This is computer generated</div>
            </div>
            {HDivider}
          </div>
        </div>
      </>
    );
  };
  const fls = {
    name: "Sales Order No.",
    value: singlelistView && singlelistView.so_number,
    date: singlelistView.contract_date,
    buyer: buyerDetails.client_name,
    buyerAddress: buyerDetails.address,
    buyerCity: buyerDetails.city,
    buyerState: buyerDetails.state,
    buyerPhone: buyerDetails.phone,
    email: buyerDetails.email,
    country:buyerDetails.country,
    totalQuantity: singlelistView.total_quantity,
    unit: singlelistView.unit,
    terms: singlelistView.terms,
  };
  return (
    <>
      <div
        style={
          preview
            ? { width: "100%", height: "100%" }
            : { height: "0", width: "0", overflow: "hidden" }
        }
      >
        <PDFExport
          ref={contentArea}
          // keepTogether="p"
          paperSize="A4"
          // margin="2cm"
          forcePageBreak=".page-break"
          fileName={`${singlelistView && singlelistView.so_number}.pdf`}
          allPages={true}
          margin={{
            bottom: 50,
            left: 25,
            right: 25,
            top: 100,
          }}
          pageTemplate={PageTemplate}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              fontFamily: "Arial",
              color: "rgba(0, 0, 0, 0.84)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "4px",
                fontSize: "0.6rem",
                textTransform: "initial !important",
              }}
            >
               <div
                style={{
                  height: "fit-content",
                  minHeight: "692px",
                  display:'flex',flexDirection:'column',justifyContent:'space-around'
                }}
              >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    fontSize: "0.6rem",
                    textDecoration: "underline",
                  }}
                >
                  This Contract is entered in to between:
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    fontSize: "0.6rem",
                  }}
                >
                  <div>
                    <strong>
                      {`${fls?.buyer?.toUpperCase()}`}
                    </strong>{" "}
                    <br />
                    <strong>
                      {`${fls.buyerAddress}, ${
                        fls.buyerCity
                      }, ${fls.buyerState?`${fls.buyerState}, `:""}${fls.country?`${fls.country}, `:""} `}
                      {/* ${"TEL:"}, ${fls.country} */}
                    </strong>{" "}
                    <br />
                    {fls.email && 
                    <strong>{`Email: ${fls.email ?fls.email.toLowerCase(): ""}`}</strong>
                    }
                    <br />
                    (Herein after called the BUYER)
                  </div>
                  AND
                  <div>
                    <strong>
                      SRI SAINATH INDUSTRY PRIVATE LIMITED <br/>BEHIND SAMRAT
                      TALKIES, STATION ROAD, RAIPUR-492001,CHHATTISGARH (INDIA)
                    </strong>
                    <br />
                    (Herein after called the SELLER)
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex",
                    fontSize: "0.6rem",
                    margin: "0",
                  }}
                >
                  Who has agreed to sell the following goods on the following
                  terms and conditions set out here under:
                </div>

                <br />
              </div>
              {addendum === true ? (
                ""
              ) : (
                <>
                  <div
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "bolder",
                      marginTop: "10px 0",
                    }}
                  >
                    Article 1: COMMODITY AND SPECIFICATION
                  </div>

                  {tableList && tableList.length > 0 ? (
                    tableList.map((single, index) => (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "0.6rem",
                              margin: "0px",
                              minWidth: "150px",
                            }}
                          >
                            PRODUCT{" "}
                          </p>
                          <p style={{ margin: "0px 0 10px 10px" }}>
                            :{" "}
                            {single["Item Category"]
                              ? single["Item Category"]
                              : ""}
                            {/* {single["Brand"] ? single["Brand"] : ""}&nbsp; */}
                            {/* {single["Item Category"] ? single["Item Category"] : ""}&nbsp; */}
                            {/* {single["Item Type"] ? single["Item Type"] : ""} % */}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "0.6rem",
                              margin: "0px ",
                              minWidth: "150px",
                            }}
                          >
                            SPECIFICATIONS{" "}
                          </p>
                          <div
                            style={{
                              margin: "0px 0 10px 10px",
                              display: "flex",
                              gap: "3px",
                            }}
                          >
                            :
                            {/* <p
                              style={{
                                margin: "0px 0 10px 10px",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                // display: "flex",
                                // width: "100%",
                              }}
                            >
                              : {single["Brand"] ? single["Brand"] : ""}
                              {single["Item Category"]
                                ? single["Item Category"]
                                : ""}
                              {single["Item Type"] ? single["Item Type"] : ""} %
                            </p> */}
                            <div>
                              {single["Specifications"] &&
                                single["Specifications"].length > 0 &&
                                single["Specifications"].map((item) => {
                                  const ss = item.nameId ?? item.name;
                                  const filterS = specifiationTypes.find(
                                    (st) => st.id === ss
                                  );
                                  return (
                                    <>
                                      <div
                                        style={{
                                          display: "grid",

                                          gridTemplateColumns: "4fr 4fr",
                                        }}
                                      >
                                        <div>{`${
                                          filterS
                                            ? `${filterS.text}`
                                            : item.name
                                        }`}</div>{" "}
                                        <div>{`: ${
                                          filterS
                                            ? `${item.value ?? ""} ${
                                                filterS.unit
                                                  ? filterS.unit.toUpperCase() ??
                                                    ""
                                                  : ""
                                              }`
                                            : item.value
                                        } ${
                                          getSpecificationName(
                                            item.condition
                                          ) ?? ""
                                        }`}</div>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "0.6rem",
                              margin: "0",
                              minWidth: "150px",
                            }}
                          >
                            ORIGIN
                          </p>
                          <p style={{ margin: "0 0 0 10px" }}>: INDIA</p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "0.6rem",
                              margin: "0",
                              minWidth: "150px",
                            }}
                          >
                            PACKING{" "}
                          </p>
                          <p style={{ margin: "0px 0 10px 10px" }}>
                            :{" "}
                            {single[`packagageArrUsed`] &&
                            single[`packagageArrUsed`].length > 0
                              ? single[`packagageArrUsed`].map((item, i) => (
                                  <>{`In ${item.size} ${item.unit} Net. new ${
                                    item.bag_type
                                  } bags${
                                    single[`packagageArrUsed`].length - 1 === i
                                      ? "."
                                      : ", "
                                  } ${
                                    single.bag_weight||single.bag_weight==="NA"||single.bag_weight==="na"
                                      ? `${single.bag_weight}.`
                                      : ""
                                  }`}</>
                                ))
                              : ""}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "0.6rem",
                              margin: "0px ",
                              minWidth: "150px",
                            }}
                          >
                            MARKING
                          </p>
                          <p style={{ margin: "0px 0 10px 10px" }}>
                            : {single["Buyer Marking"]}
                          </p>
                        </div>
                      </>
                    ))
                  ) : (
                    <div>NO PRODUCT</div>
                  )}
                </>
              )}
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                Article 2: QUANTITY / PRICE/ TOTAL AMOUNT
              </div>
              {tableList && tableList.length > 0 ? (
                tableList.map((single, index) => (
                  <>
                    {/* {handlePrice(single,index)} */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        QUANTITY
                      </p>
                      <p style={{ margin: "0px 0 10px 10px" }}>
                        :{" "}
                        {`${
                          single["Total Item QuantityUnit"]
                            ? single["Total Item QuantityUnit"]
                            : ""
                        } ${single.quantity_tolerance ?? ""} ${
                          singlelistView.total_no_of_containers ?`in ${singlelistView.total_no_of_containers} FCL`:""
                        }`}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        PRICE
                      </p>
                      {deliveryTerm ? (
                        deliveryTerm
                      ) : (
                        <div
                          style={{
                            margin: "0px 0 10px 10px",
                            // whiteSpace: "pre",
                          }}
                        >
                          {/* <p style={{ margin: "0px 0 10px 10px" }}> */}:{" "}
                          {singlelistView.so_delivery_terms
                            ? singlelistView.so_delivery_terms[0] +
                              " " +
                              singlelistView.so_delivery_terms[1][index] +
                              "P" +
                              singlelistView.so_delivery_terms[2] +
                              " " +
                              singlelistView.so_delivery_terms[3] +
                              " " +
                              " " +
                              "in" +
                              " " +
                              singlelistView.so_delivery_terms[4] +
                              // +
                              // " " +
                              // singlelistView.so_delivery_terms[5] +
                              // " " +
                              // singlelistView.so_delivery_terms[6][index] +
                              // " " +
                              // " " +
                              // "in" +
                              singlelistView.so_delivery_terms[7]
                            : ""}
                          {/* </p> */}
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "0px ",
                          minWidth: "150px",
                        }}
                      >
                        VALUE
                      </p>
                      {ValueinWords ? (
                        ValueinWords
                      ) : (
                        <p style={{ margin: "0px 0 10px 10px" }}>
                          :{" "}
                          {filterData(
                            CurrencyList,
                            "id",
                            singlelistView.currency,
                            "id",
                            "onlyOne"
                          )
                            ? filterData(
                                CurrencyList,
                                "id",
                                singlelistView.currency,
                                "id",
                                "onlyOne"
                              ).name +
                              " " +
                              Number(single["Contract Rate"]) *
                                Number(single["total_quantity"]) +
                              "/- (" +
                              filterData(
                                CurrencyList,
                                "id",
                                singlelistView.currency,
                                "id",
                                "onlyOne"
                              ).name +
                              " " +
                              converter.toWords(
                                Number(single["Contract Rate"]) *
                                  Number(single["total_quantity"])
                              ) +
                              " only.)"
                            : " "}
                        </p>
                      )}
                    </div>
                  </>
                ))
              ) : (
                <div>NO PRODUCT</div>
              )}
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                Article 3: DELIVERY/ LOADPORT/ DISCHARGE PORT/ SHIPPING TERMS:
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0",
                    minWidth: "150px",
                  }}
                >
                  Shipment
                </p>
                <p style={{ margin: "0 0 0 10px" }}>{`:  ${
                  singlelistView.shipment_date
                    ? moment(singlelistView.shipment_date).format("DD/MM/YYYY")
                    : ""
                }`}</p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Load Port
                </p>
                <p style={{ margin: "0 0 0 10px" }}>
                  :{" "}
                  {singlelistView.load_ports.map((sp) =>
                    sp === "Any Indian Port"
                      ? "Any Indian Port"
                      : filterData(PortsList, "id", Number(sp), "onlyOne")
                      ? filterData(PortsList, "id", Number(sp), "onlyOne").name
                      : ""
                  )}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0px ",
                    minWidth: "150px",
                  }}
                >
                  Discharge Port
                </p>
                <p style={{ margin: "0 0 0 10px" }}>
                  :{" "}
                  {singlelistView.discharge_port
                    ? singlelistView.discharge_port
                    : ""}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p
                  style={{
                    fontSize: "0.6rem",
                    margin: "0",
                    minWidth: "150px",
                  }}
                >
                  Shipment by :
                </p>
                <p style={{ margin: "0px 0 10px 10px" }}>
                  : {singlelistView.shipment_by}
                  {/* {singlelistView.shipping_term &&
                  filterData(
                    masters["ShippingTerms"],
                    "id",
                    Number(singlelistView.shipping_term),
                    "onlyOne"
                  )
                    ? filterData(
                        masters["ShippingTerms"],
                        "id",
                        Number(singlelistView.shipping_term),
                        "onlyOne"
                      ).value
                    : ""} */}
                </p>
              </div>
              </div>
              {addendum === true ? (
                ""
              ) : (
                <>
                  <div className="page-break"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "bolder",
                      marginTop: "15px",
                    }}
                  >
                    Article 4: PAYMENT{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "0.6rem",
                        margin: "0",
                        minWidth: "150px",
                      }}
                    >
                      Payment
                    </p>
                    <p style={{ margin: "0px 0 10px 10px" }}>
                      {`: ${
                        singlelistView.so_payment_term &&
                        filterData(
                          SoPaymentList,
                          "id",
                          Number(singlelistView.so_payment_term),
                          "onlyOne"
                        )
                          ? filterData(
                              SoPaymentList,
                              "id",
                              Number(singlelistView.so_payment_term),
                              "onlyOne"
                            ).term
                          : ""
                      } ${singlelistView.duty_payment_term ?? ""}`}
                    </p>
                  </div>
                </>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    fontSize: "0.6rem",
                    minWidth: "150px",
                  }}
                >
                  Seller’s Bank Details
                </div>
                <div
                  style={{
                    margin: "0px 0 10px 10px",
                    display: "flex",
                    gap: "3px",
                  }}
                >
                  :
                  <div>
                    <div>BANK OF BARODA, MAIN BRANCH</div>
                    <div>
                      GAUSHALA COMPLEX,K.K.ROAD,RAIPUR-Chhattisgarh-INDIA{" "}
                    </div>
                    <div>Swift Code: BARBINBBRAI</div>
                    <div> Account No. 05100200001242</div>
                  </div>
                </div>
              </div>

              {/* <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bolder",
                  marginTop: "15px",
                }}
              >
                POLICY
              </div>
              {HDivider} */}
              {addendum === true ? (
                ""
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: "0px",
                    }}
                  >
                    <p
                      style={{
                        margin: "10px 0 10px 0px",
                      }}
                    >
                      {parse(singlelistView.policy_of_so_policy)}
                      {/* // &&
                // filterData(
                //   soPolicyList,
                //   "id",
                //   Number(singlelistView.so_policy),
                //   "onlyOne"
                // )
                //   ? parse(
                //       filterData(
                //         soPolicyList,
                //         "id",
                //         Number(singlelistView.so_policy),
                //         "onlyOne"
                //       ).policy
                //     )
                //   : ""} */}
                    </p>
                  </div>
                </>
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  // justifyContent: "space-evenly",
                  flexDirection: "row",

                  // justifyContent: "center",
                  // alignItems: "center",
                  // margin: "0 20px",
                  marginTop: "40px",
                  fontSize: "0.6rem",
                }}
              >
                <div style={{ width: "fit-content", minWidth: "50%" , fontWeight: 'bold'}}>
                  SELLER
                  <br />
                  SRI SAINATH INDUSTRY PVT LIMITED
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  (Authorised Signatory)
                </div>
                <div style={{ fontWeight: 'bold'}}>
                  BUYER
                  <br />
                  {buyerDetails?.client_name?.toUpperCase()}
                  <br />
                  <br />
                  <br />
                  <br />
                  Name and designation of the person <br />
                  (Authorised Signatory)
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
      {preview ? (
        ""
      ) : (
        <CssButton
          onClick={() => {
            handleExportWithFunction();
          }}
          style={{
            background: "rgb(241 154 48)",
            color: "white",
            marginRight: "10px",
            textTransform: "initial !important",
          }}
        >
          Download <DownloadIcon fontSize="small" />
        </CssButton>
      )}
    </>
  );
}

export default SalesOrderPdf;
