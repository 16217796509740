import React from "react";
import { DashBoardIcon } from "../layoutComponents/icons";
import { primary } from "../styles/colors";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import PurchaseIcon from "@mui/icons-material/AddShoppingCart";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import DispatchesIcon from "@mui/icons-material/LocalShipping";
import InventoryIcon from "@mui/icons-material/Inventory";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import SailingOutlinedIcon from "@mui/icons-material/SailingOutlined";
import SalesOrdersIcon from "@mui/icons-material/Sell";
import DirectionsBusOutlinedIcon from "@mui/icons-material/DirectionsBusOutlined";
import BrokersIcon from "@mui/icons-material/Group";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import { withStyles } from "@material-ui/styles";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import ProductionQuantityLimitsOutlinedIcon from "@mui/icons-material/ProductionQuantityLimitsOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from '@mui/icons-material/Home';
import { POIcon, SOIcon, PORTIcon,BrandIcon ,VendorsIcon,SettingIcon,UserPermissionIcon} from "../styles/Icons";
//  * ⚠ These are used just to render the Sidebar!
//  * You can include any link here, local or external.
//  *
//  * If you're looking to actual Router routes, go to
//  * `routes/index.js`
//  */

// export const CssAccountBoxOutlinedIcon = withStyles({
//   root: {
//  "&.MuiListItemIcon-root": {

//     color:" rgba(0, 0, 0, 0.54)",
//     display:" inline-flex",
//     minWidth: "40px",
//     flexShrink: "0",

// }
//   },
// })(AccountBoxOutlinedIcon)

const routes = [
  {
    path: null,
    title: "Masters",
    childRoutes: [
      {
        path: "/Clients",
        title: <span style={{ marginLeft: "-20px" }}>Clients</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <GroupOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/SurveyCompanys",
        title: <span style={{ marginLeft: "-20px" }}>Survey Companies</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <PollOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/ItemCategories",
        title: <span style={{ marginLeft: "-20px" }}>Item Categories</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <CategoryOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/ItemTypes",
        title: <span style={{ marginLeft: "-20px" }}>Item Types</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <LoyaltyOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/PaymentTerms",
        title: <span style={{ marginLeft: "-20px" }}>PO Payment Terms</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <PaymentsOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      // {
      //   path: '/ContractTypes',
      //   title:  <span style={{marginLeft:"-20px"}}>Contract Types</span>,
      //   access: 'all',
      //   icon: (path, chectSelected) => (
      //     <ArticleOutlinedIcon
      //       style={{ color: chectSelected(path) ? primary : 'white' }}
      //     />
      //   ),
      // },
      {
        path: "/Packagings",
        title: <span style={{ marginLeft: "-20px" }}>Packagings</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <ShoppingBagOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/HsNs",
        title: <span style={{ marginLeft: "-20px" }}>HSN Code</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <SourceOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Beneficiarys",
        title: <span style={{ marginLeft: "-20px" }}>Beneficiaries</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <CorporateFareOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/Shippers",
        title: <span style={{ marginLeft: "-20px" }}>Shippers</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <LocalShippingOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/RiceBagSellers",
        title: <span style={{ marginLeft: "-20px" }}>Rice Bag Sellers</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <ProductionQuantityLimitsOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Notifys",
        title: <span style={{ marginLeft: "-20px" }}>Notify</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <NotificationsOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Currencys",
        title: <span style={{ marginLeft: "-20px" }}>Currency</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <CurrencyRupeeOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/specification-types",
        title: <span style={{ marginLeft: "-20px" }}>Specification Types</span>,
        access: "all",
        icon: (path, chectSelected) => (
          <CurrencyRupeeOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      // {
      //   path: "/specification-conditions",
      //   title: <span style={{ marginLeft: "-20px" }}>Specification Conditions</span>,
      //   access: "all",
      //   icon: (path, chectSelected) => (
      //     <CurrencyRupeeOutlinedIcon
      //       style={{ color: chectSelected(path) ? primary : "white" }}
      //     />
      //   ),
      // },
      {
        path: "/SOpaymentTerms",
        title: <span style={{ marginLeft: "-20px" }}>SO Payment Terms</span>,

        access: "all",
        icon: (path, chectSelected) => (
          <PaidOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Incoterms",
        title: <span style={{ marginLeft: "-20px" }}>Incoterm</span>,

        access: "all",
        icon: (path, chectSelected) => (
          <DashBoardIcon color={chectSelected(path) ? primary : "white"} />
        ),
      },
      {
        path: "/ShippingTerms",
        title: <span style={{ marginLeft: "-20px" }}>Shipping Terms</span>,

        access: "all",
        icon: (path, chectSelected) => (
          <GavelOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },

      {
        path: "/Precarriers",
        title: <span style={{ marginLeft: "-20px" }}>Pre-Carriers</span>,

        access: "all",
        icon: (path, chectSelected) => (
          <DashBoardIcon color={chectSelected(path) ? primary : "white"} />
        ),
      },
      {
        path: "/Policys",
        title: <span style={{ marginLeft: "-20px" }}>SO Policy</span>,

        access: "all",
        icon: (path, chectSelected) => (
          <PolicyOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],
    access: "Masters",
    icon: (path, chectSelected,child) => (
      <SettingIcon
      color={chectSelected(path) ? primary : "white" }
      />
    ),
  },
  {
    path: "/UsersAndPermissions",
    title: <span style={{ marginLeft: "-20px" }}>Users & Permissions</span>,
    access: "Users & Permissions",
    icon: (path, chectSelected) => (
      <UserPermissionIcon
      color={chectSelected(path) ? primary : "white" }
      />
    ),
  },
  {
    path: null,
    title: "Partners",
    childRoutes: [
      {
        path: "/Millers",
        title: <span style={{ marginLeft: "-20px" }}>Millers</span>,
        access: "Millers",
        icon: (path, chectSelected) => (
          <FactoryOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Brokers",
        title: <span style={{ marginLeft: "-20px" }}>Brokers</span>,
        access: "Brokers",
        icon: (path, chectSelected) => (
          <GroupOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
      {
        path: "/Transporters",
        title: <span style={{ marginLeft: "-20px" }}>Transporters</span>,
        access: "Transporters",
        icon: (path, chectSelected) => (
          <DirectionsBusOutlinedIcon
            style={{ color: chectSelected(path) ? primary : "white" }}
          />
        ),
      },
    ],
    access: "Partners",
    icon: (path, chectSelected,child) => (
      <VendorsIcon  color={chectSelected(path) ? primary : "white" } />
    ),
  },


  {
    path: "/BrandsManagements",
    title: <span style={{ marginLeft: "-20px" }}>Bags Management</span>,
    access: "Brands Management",
    icon: (path, chectSelected) => (
      <BrandIcon
      color={chectSelected(path) ? primary : "white" }
        // style={{
        //   color: chectSelected(path) ? primary : "white",
        //   minWidth: "0px",
        // }}
      />
    ),
  },
  // {
  //   path: "/Millers",
  //   title: <span style={{ marginLeft: "-20px" }}>Millers</span>,
  //   access: "Millers",
  //   icon: (path, chectSelected) => (
  //     <FactoryOutlinedIcon
  //       style={{ color: chectSelected(path) ? primary : "white" }}
  //     />
  //   ),
  // },
  // {
  //   path: "/Brokers",
  //   title: <span style={{ marginLeft: "-20px" }}>Brokers</span>,
  //   access: "Brokers",
  //   icon: (path, chectSelected) => (
  //     <GroupOutlinedIcon
  //       style={{ color: chectSelected(path) ? primary : "white" }}
  //     />
  //   ),
  // },
  // {
  //   path: "/Transporters",
  //   title: <span style={{ marginLeft: "-20px" }}>Transporters</span>,
  //   access: "Transporters",
  //   icon: (path, chectSelected) => (
  //     <DirectionsBusOutlinedIcon
  //       style={{ color: chectSelected(path) ? primary : "white" }}
  //     />
  //   ),
  // },

  {
    path: "/SalesOrders",
    title: <span style={{ marginLeft: "-20px" }}>Sales Orders</span>,
    access: "Sales Orders",
    icon: (path, chectSelected) => (
      <SOIcon
        color={chectSelected(path) ? primary : "white" }
       
      />
    ),
  },
  {
    path: "/Ports",
    title: <span style={{ marginLeft: "-20px" }}>Ports</span>,
    access: "Ports",
    icon: (path, chectSelected) => (
      <PORTIcon
      color={chectSelected(path) ? primary : "white" }
      />
    ),
  },
  {
    path: "/PurchaseOrders",
    title: <span style={{ marginLeft: "-20px" }}>Purchase Orders</span>,
    access: "Purchase Orders",
    icon: (path, chectSelected) => (
      <POIcon
      color={chectSelected(path) ? primary : "white" }
      />
    ),
  },
  {
    path: "/Homes",
    title: <span style={{ marginLeft: "-20px" }}>Homes</span>,
    access: "Homes",
    icon: (path, chectSelected) => (
      <HomeIcon
        size="large"
        style={{
          color: chectSelected(path) ? primary : "white",
          minWidth: "0px",
        }}
      />
    ),
  },
];

export default routes;
// style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"}
