import { MenuItem } from "@material-ui/core";
import { divide, multiply, sum } from "lodash";

export const filterData = (array, field, compareValue, getSingleObj, yearToCompare) => {
  if (array && array.length > 0 && field && (compareValue || compareValue === false)) {
    const filteredData = array.filter((singleObj) => {
      if (singleObj[field] || singleObj[field] === false) {
        return singleObj[field] === compareValue;
      } else {
        return false;
      }
    });
    if (filteredData && filteredData.length > 0 && filteredData[0]) {
      if (getSingleObj) {
        return filteredData[0];
      } else {
        return filteredData;
      }
    }
  } else if (array && array.length > 0 && field) {
    const filteredData = array.filter((singleObj) => {
      if (singleObj[field]) {
        return singleObj[field];
      } else {
        return false;
      }
    });
    if (filteredData && filteredData.length > 0 && filteredData[0]) {
      if (getSingleObj) {
        return filteredData[0];
      } else {
        return filteredData;
      }
    }
  }
};
export const listItemDropdown = (array, field, content) => {
  return array && array.length > 0 ? (
    content
  ) : (
    <MenuItem
      disabled={true}
      key={`No ${field} added yet`}
      value={`No ${field} added yet`}
      id={`No ${field} added yet`}
    >
      {`No ${field} added yet`}
    </MenuItem>
  );
};
export const StopScroll = (e) => {
  e.target.addEventListener(
    "wheel",
    function (e) {
      e.preventDefault();
    },
    { passive: false }
  );
};
export const scrollDiv = (id) => {
  const chat = document.getElementById(id);
  chat.scrollIntoView();
};
export const calculatePercentage = (originalAmount, percentage) => {
  return divide(multiply(originalAmount ? Number(originalAmount) : 0, percentage ? Number(percentage) : 0), 100);
};
export const calculateGstAmount = (originalAmount, percentage,percentageFrom) => {
  return sum([calculatePercentage(percentageFrom?percentageFrom:originalAmount, percentage), originalAmount ? Number(originalAmount) : 0]);
};
export const calculateExchangeValue = (amount, rate) => {
  return multiply(amount ? Number(amount) : 0, rate ? Number(rate) : 0);
};
export const calculatePrice = (amount, weight) => {
  return divide(amount ? Number(amount) : 0, weight ? Number(weight) : 0);
};