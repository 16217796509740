import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { CssFilledSelectTextField } from '../styles/StyledComponents'
import { MenuItem } from '@mui/material'
const CssTextField = withStyles({
  root: {
    '& .MuiSelect-select': {
      width: '100%',

      boxShadow:
        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    
      textTransform: 'capitalize',
      borderRadius: '7px',
      background: 'white',
    },
    '& .MuiInputLabel-filled': {
      transform: 'translate(10px, 10px) scale(1)',
    },
  },
})(CssFilledSelectTextField)
export default function RaisedSelectBar({
  data,
  labelField,
  selctedData,
  handleSearch,
  hintText,
}) {
  return (
    // <div style={{ minWidth: '90px', margin: '0 10px' }}>
    <CssTextField
      autoComplete="off"
      value={
        selctedData ? selctedData : '' //
      }
      onChange={handleSearch}
      style={{
        width: 'calc(100% - 10px)',

        margin: '16px 5px 0px',
      }}
      name={labelField}
      id="outlined-select-currency-native"
      select
      label={!selctedData && hintText}
      variant="filled"
    >
      {data.map((option) => (
        <MenuItem
          key={option[labelField]}
          value={option[labelField]}
          id={option[labelField]}
          style={{ textTransform: 'capitalize' }}
        >
          {option[labelField]}
        </MenuItem>
      ))}
    </CssTextField>

  )
}
