import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PurchaseOrdersComponent from "./PurchaseOrdersComponent";
import { PurchaseOrdersFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";
function PurchaseOrdersContainer(props) {
  const [masters, setMasters] = useState({});

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    // return () => {
    fetchUsers();
    fetchAllData();
    // };
    //     let cancel = false;

    // someAsyncFunction().then(() => {
    //   if (cancel) return;
    //   setIsVisible(false);
    // });

    // return () => {
    //   cancel = true;
    // }
  }, []);
  const fetchAllData = () => {
    props
      .PurchaseOrdersFunction(
        "get",
        "masters/miller/list",
        null,
        "MillerList",
        null,
        { is_active: "True" }
      )
      .then((MillerList) => {
        if (MillerList) {
          const newMasters = masters;
          newMasters.MillerList = MillerList;
          setMasters(newMasters);
        }
        props
          .PurchaseOrdersFunction(
            "get",
            "masters/item-category/list",
            null,
            //state.singlelistView.id,
            "ItemCateoryList",
            null,
            { is_active: "True" }
          )
          .then((ItemCateoryList) => {
            if (ItemCateoryList) {
              const newMasters = masters;
              newMasters.ItemCateoryList = ItemCateoryList;
              setMasters(newMasters);
            }
            props
              .PurchaseOrdersFunction(
                "get",
                "masters/item-type/list",
                null,
                //state.singlelistView.id,
                "ItemList",
                null,
                { is_active: "True" }
              )
              .then((ItemList) => {
                if (ItemList) {
                  const newMasters = masters;
                  newMasters.ItemList = ItemList;
                  setMasters(newMasters);
                }
                props
                  .PurchaseOrdersFunction(
                    "get",
                    "masters/brand/list",
                    null,
                    //state.singlelistView.id,
                    "BrandList",
                    null,
                    { is_active: "True" }
                  )
                  .then((BrandList) => {
                    if (BrandList) {
                      const newMasters = masters;
                      newMasters.BrandList = BrandList;
                      setMasters(newMasters);
                    }
                    props
                      .PurchaseOrdersFunction(
                        "get",
                        "masters/packaging/list",
                        null,
                        //state.singlelistView.id,
                        "PackagingList",
                        null,
                        { is_active: "True" }
                      )
                      .then((PackagingList) => {
                        if (PackagingList) {
                          const newMasters = masters;
                          newMasters.PackagingList = PackagingList;
                          setMasters(newMasters);
                        }
                        props
                          .PurchaseOrdersFunction(
                            "get",
                            "masters/broker/list",
                            null,
                            //state.singlelistView.id,
                            "BrokerList",
                            null,
                            { is_active: "True", broker_for: "PO" }
                          )
                          .then((BrokerList) => {
                            if (BrokerList) {
                              const newMasters = masters;
                              newMasters.BrokerList = BrokerList;
                              setMasters(newMasters);
                            }
                            props
                              .PurchaseOrdersFunction(
                                "get",
                                "masters/contract-type/list",
                                null,
                                //state.singlelistView.id,
                                "ContractTypeList",
                                null,
                                { is_active: "True" }
                              )
                              .then((ContractTypeList) => {
                                if (ContractTypeList) {
                                  const newMasters = masters;
                                  newMasters.ContractTypeList =
                                    ContractTypeList;
                                  setMasters(newMasters);
                                }
                                props
                                  .PurchaseOrdersFunction(
                                    "get",
                                    "masters/port/list",
                                    null,
                                    //state.singlelistView.id,
                                    "PortsList"
                                  )
                                  .then((PortsList) => {
                                    if (PortsList) {
                                      const newMasters = masters;
                                      newMasters.PortsList = PortsList;
                                      setMasters(newMasters);
                                    }
                                    props
                                      .PurchaseOrdersFunction(
                                        "get",
                                        "masters/payment-terms/list",
                                        null,
                                        //state.singlelistView.id,
                                        "PaymentTermList",
                                        null,
                                        { is_active: "True" }
                                      )
                                      .then((PaymentTermList) => {
                                        if (PaymentTermList) {
                                          const newMasters = masters;
                                          newMasters.PaymentTermList =
                                            PaymentTermList;
                                          setMasters(newMasters);
                                        }
                                        props
                                          .PurchaseOrdersFunction(
                                            "get",
                                            "masters/transporter/list",
                                            null,
                                            //state.singlelistView.id,
                                            "TransporterList",
                                            null,
                                            { is_active: "True" }
                                          )
                                          .then((TransporterList) => {
                                            if (TransporterList) {
                                              const newMasters = masters;
                                              newMasters.TransporterList =
                                                TransporterList;
                                              setMasters(newMasters);
                                            }
                                            props
                                              .PurchaseOrdersFunction(
                                                "get",
                                                "masters/hsn/list",
                                                null,
                                                //state.singlelistView.id,
                                                "HSNlist",
                                                null,
                                                { is_active: "True" }
                                              )
                                              .then((HSNlist) => {
                                                if (HSNlist) {
                                                  const newMasters = masters;
                                                  newMasters.HSNlist = HSNlist;
                                                  setMasters(newMasters);
                                                }
                                                props
                                                  .PurchaseOrdersFunction(
                                                    "get",
                                                    "dispatch/sales-order/list",
                                                    null,
                                                    //state.singlelistView.id,
                                                    "SalesOrderList"
                                                  )
                                                  .then((SalesOrderList) => {
                                                    if (SalesOrderList) {
                                                      const newMasters =
                                                        masters;
                                                      newMasters.SalesOrderList =
                                                        SalesOrderList;
                                                      setMasters(newMasters);
                                                    }
                                                    props
                                                      .PurchaseOrdersFunction(
                                                        "get",
                                                        "masters/survey-company/list",
                                                        null,
                                                        //state.singlelistView.id,
                                                        "SurveyCompanyList",
                                                        null,
                                                        { is_active: "True" }
                                                      )
                                                      .then(
                                                        (SurveyCompanyList) => {
                                                          if (
                                                            SurveyCompanyList
                                                          ) {
                                                            const newMasters =
                                                              masters;
                                                            newMasters.SurveyCompanyList =
                                                              SurveyCompanyList;
                                                            setMasters(
                                                              newMasters
                                                            );
                                                          }

                                                          props
                                                            .PurchaseOrdersFunction(
                                                              "get",
                                                              "dispatch/rice-bag-movement/list",
                                                              null,
                                                              //state.singlelistView.id,
                                                              "ricebag",
                                                              null,
                                                              {
                                                                is_active:
                                                                  "True",
                                                              }
                                                            )
                                                            .then((ricebag) => {
                                                              if (ricebag) {
                                                                const newMasters =
                                                                  masters;
                                                                newMasters.ricebag =
                                                                  ricebag;
                                                                setMasters(
                                                                  newMasters
                                                                );
                                                              }
                                                              props
                                                                .PurchaseOrdersFunction(
                                                                  "get",
                                                                  "dispatch/po-packaging/list",
                                                                  null,
                                                                  //state.singlelistView.id,
                                                                  "poPackahging",
                                                                  null,
                                                                  {
                                                                    is_active:
                                                                      "True",
                                                                  }
                                                                )
                                                                .then(
                                                                  (
                                                                    poPackahging
                                                                  ) => {
                                                                    if (
                                                                      poPackahging
                                                                    ) {
                                                                      const newMasters =
                                                                        masters;
                                                                      newMasters.poPackahging =
                                                                        poPackahging;
                                                                      setMasters(
                                                                        newMasters
                                                                      );
                                                                    }
                                                                    props
                                                                      .PurchaseOrdersFunction(
                                                                        "get",
                                                                        "masters/surveyor/list",
                                                                        null,
                                                                        //state.singlelistView.id,
                                                                        "allsurveyorList",
                                                                        null,
                                                                        {
                                                                          is_active:
                                                                            "True",
                                                                        }
                                                                      )
                                                                      .then(
                                                                        (
                                                                          allsurveyorList
                                                                        ) => {
                                                                          if (
                                                                            allsurveyorList
                                                                          ) {
                                                                            const newMasters =
                                                                              masters;
                                                                            newMasters.allsurveyorList =
                                                                              allsurveyorList;
                                                                            setMasters(
                                                                              newMasters
                                                                            );
                                                                          }
                                                                          props
                                                                            .PurchaseOrdersFunction(
                                                                              "get",
                                                                              "masters/specification-type/list",
                                                                              null,
                                                                              //state.singlelistView.id,
                                                                              "specifiationTypes",
                                                                              null,
                                                                              {
                                                                                is_active:
                                                                                  "True",
                                                                              }
                                                                            )
                                                                            .then(
                                                                              (
                                                                                specifiationTypes
                                                                              ) => {
                                                                                if (
                                                                                  specifiationTypes
                                                                                ) {
                                                                                  const newMasters =
                                                                                    masters;
                                                                                  newMasters.specifiationTypes =
                                                                                    specifiationTypes;
                                                                                  setMasters(
                                                                                    newMasters
                                                                                  );
                                                                                  setLoader(
                                                                                    false
                                                                                  );
                                                                                } else {
                                                                                  setLoader(
                                                                                    false
                                                                                  );
                                                                                }
                                                                              }
                                                                            );
                                                                        }
                                                                      );
                                                                  }
                                                                );
                                                            });
                                                        }
                                                      );
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
  };
  const fetchUsers = () => {
    if (props.user.uid) {
      props.PurchaseOrdersFunction(
        "get",
        EndPoints["PurchaseOrders"],
        null,
        null,
        null,
        {
          status: "OPEN",
        }
      );
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return (
    <PurchaseOrdersComponent
      {...props}
      params={params}
      masters={masters}
      loader={loader}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  PurchaseOrders: state.modules.PurchaseOrders,
  loading: state.modules.loading,
  [EndPoints["PurchaseOrders"]]: state.modules[EndPoints["PurchaseOrders"]],
  PODispatchedList: state.modules.PODispatchedList,
  PO_Items_List: state.modules.PO_Items_List,
});
const mapDispatchToProps = {
  PurchaseOrdersFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrdersContainer);
