import { Paper } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import TableComponent from "../../layoutComponents/TableComponent";
import { filterData } from "../../Regex";
import {
  CssDialog,
  CSVButton,
  StyledButton,
  StyledCircularProgress,
  StyledLoadingButton,
} from "../../styles/StyledComponents";
import { multiply, divide } from "lodash";
import CommercialPackingList from "./PDFS/CommercialPackingList";
import { Download, EditIcon } from "../../styles/Icons";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import CreateDialog from "../../layoutComponents/CreateDialog";
import { primary } from "../../styles/colors";

function PaymentList(props) {
  const {
    selectedCustomInvoice,
    noAddButton,
    postData,
    updateValues,
    changeTab,
    masters,
    singlelistView,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [paymentList, setPaymentList] = useState(false);
  const [editList, setEditList] = useState(false);
  const [fieldsValue, setFieldsValue] = useState({});
  const [customInvoiceArray, setCustomInvoiceArray] = useState([]);
  const [csvArray, setCSVArray] = useState({
    header: [],
    data: [],
  });
  const [loader, setLoad] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleFieldValueChange = (allData, field) => {
    setFieldsValue((prevValue) => {
      return {
        ...prevValue,
        [field]: allData[field],
      };
    });
    console.log(allData, fieldsValue);
  };

  const loadCustomInvoiceData = () => {
    console.log(props);
    // if (selectedCustomIn) {
    setLoad(true);
    postData(
      "get",
      `arrival/commercial-invoice/${props.selectedCustomInvoice.id}`,
      null,
      "arr",
      null,
      {}
    ).then((arr) => {
      if (arr) {
        setCustomInvoiceArray(arr?.custom_invoice_details);
      }
      setLoad(false);
    });
    // }
  };

  const loadPaymentList = () => {
    console.log("PROP", props)
    setLoad(true);
    postData(
      "get",
      "arrival/payment-transaction/list",
      null,
      "arr",
      null,
      {
        commercial_invoice: props.selectedCustomInvoice.id,
        is_active: "True"
      }
    ).then((arr) => {
      if (arr) {
        setPaymentList(arr);
        let Header = [
          "Com. Inv. No.",
          "Inv Date",
          "Cust Inv.No.",
          "Cinv Date",
          "Bl No",
          "Pay Status",
          "Cont No",
          "Party Name",
          "Icd",
          "Dp",
          "Qty (Mt)",
          "Com",
          "Inv Amt",
          "Amt Rcvd",
          "Short",
          "Bill Id No",
          "Rate",
          "Real",
        ];
        let csvData = [];
        arr.map((singlePayment) => {
          const customeInvoice = singlePayment.custom_invoice_details;
          const commercialInvoice = singlePayment.commercial_invoice_details;
          csvData.push({
            "Com. Inv. No.": commercialInvoice.invoice_number,
            "Inv Date": moment(commercialInvoice.invoice_date).format(
              "ddd, MMM Do YYYY"
            ),
            "Cust Inv.No.": customeInvoice.invoice_number,
            "Cinv Date": moment(customeInvoice.invoice_date).format(
              "ddd, MMM Do YYYY"
            ),
            "Bl No": customeInvoice.bl_number,
            "Pay Status": singlePayment.payment_status,
            "Cont No": customeInvoice.no_of_container,
            "Party Name": customeInvoice.party_name,
            Icd: customeInvoice.place_of_receipt_by_precarrier,
            Dp: customeInvoice.discharge_port,
            "Qty (Mt)": customeInvoice.total_item_quantity,
            Com: commercialInvoice.commodity_name,
            "Inv Amt": singlePayment.invoice_amount,
            "Amt Rcvd": singlePayment.amount_received,
            Short: singlePayment.short,
            "Bill Id No": singlePayment.bill_no,
            Rate: singlePayment.rate,
            Real:
              singlePayment.real_status === "CASH_RATE"
                ? "CASH RATE"
                : "FORWARD",
          });
        });
        setCSVArray({
          header: Header,
          data: csvData,
        });
      }
      setLoad(false);
    });
  };

  const loadEditList = (id) => {
    setLoad(true);
    postData(
      "get",
      `arrival/payment-transaction/${id}`,
      null,
      "paymentDetail",
      null,
      {}
    ).then((paymentDetail) => {
      if (paymentDetail) {
        setEditList(paymentDetail);
        setOpenDialog(true);
      }
      setLoad(false);
    });
  };

  useEffect(() => {
    if (editList) {
      setFieldsValue(editList);
    }
  }, [editList]);

  useEffect(() => {
    loadCustomInvoiceData();
    loadPaymentList();
  }, []);

  const InputFields = [
    {
      name: "Custom Invoice",
      type: "select",
      dataType: "text",
      // disabled: editList ? true : false,
      array: customInvoiceArray,
      value: "invoice_number",
      defaultValue: {
        value: editList.custom_invoice_details
          ? editList.custom_invoice_details.invoice_number
          : "",
        id: editList.custom_invoice_details
          ? editList.custom_invoice_details.invoice_id
          : "",
      },

      api: "custom_invoice",
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Custom Invoice");
        return submittedData;
      },
    },
    {
      name: "Payment Status",
      type: "select",
      dataType: "text",
      array: [
        { name: "PENDING", id: "PENDING" },
        { name: "RECEIVED", id: "RECEIVED" },
      ],
      value: "name",
      api: "payment_status",
      // disabled: editList && editList?.payment_status ? true : false,
      defaultValue: {
        value:
          editList && editList?.payment_status ? editList?.payment_status : "",
        id:
          editList && editList?.payment_status ? editList?.payment_status : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Payment Status");
        return submittedData;
      },
    },
    {
      name: "Invoice Amount",
      type: "textField",
      dataType: "number",
      update: true,
      defaultValue: {
        value:
          editList && editList["invoice_amount"]
            ? editList["invoice_amount"]
            : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Invoice Amount");
        return submittedData;
      },
      api: "invoice_amount",
    },
    {
      name: "Received Amount",
      type: "textField",
      dataType: "number",
      update: true,
      defaultValue: {
        value:
          editList && editList["amount_received"]
            ? editList["amount_received"]
            : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Received Amount");
        return submittedData;
      },
      api: "amount_received",
    },
    {
      name: "Short",
      type: "textField",
      dataType: "number",
      update: true,
      defaultValue: {
        value: editList && editList["short"] ? editList["short"] : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Short");
        return submittedData;
      },
      api: "short",
    },
    {
      name: "Bill Id No",
      type: "textField",
      dataType: "text",
      update: true,
      defaultValue: {
        value: editList && editList["bill_no"] ? editList["bill_no"] : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Bill Id No");
        return submittedData;
      },
      api: "bill_no",
    },
    {
      name: "Rate",
      type: "textField",
      dataType: "number",
      update: true,
      defaultValue: {
        value: editList && editList["rate"] ? editList["rate"] : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Rate");
        return submittedData;
      },
      api: "rate",
    },
    {
      name: "Real Status",
      type: "select",
      dataType: "text",
      array: [
        { name: "CASH RATE", id: "CASH_RATE" },
        { name: "FORWARD", id: "FORWARD" },
      ],
      value: "name",
      api: "real_status",
      // disabled: editList && editList["real_status"] ? true : false,
      defaultValue: {
        value:
          editList && editList["real_status"]
            ? editList["real_status"] === "CASH_RATE"
              ? "CASH RATE"
              : "FORWARD"
            : "",
        id: editList && editList["real_status"] ? editList["real_status"] : "",
      },
      onChange: async (submittedData) => {
        handleFieldValueChange(submittedData, "Real Status");
        return submittedData;
      },
    },
  ];

  return (
    <Paper className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary d-flex d-flex-column  height-100 width-100 textC minHeightFC">
      <div
        className="width-100 d-flex justifyFE alignC"
        style={{ padding: "0 15px" }}
      >
        <StyledButton
          name="Create New"
          withBg={true}
          maxHeight="35.74px"
          margin="5px 10px"
          onClick={(e) => {
            e.stopPropagation();
            setOpenDialog(true);
          }}
        />

        <CSVButton
          csvHeader={csvArray.header}
          csvData={csvArray.data}
          fileName={"Payment List"}
        />
      </div>
      {successMsg ? (
        <SnackbarComponent
          autoHideDuration={4000}
          success={successMsg}
          snackbarClose={() => {
            // setError(false);
            setSuccessMsg("");
          }}
        />
      ) : (
        ""
      )}
      {openDialog === true ? (
        <CreateDialog
          noAddAnother={true}
          closeOnSave={true}
          isSetLoad={loader}
          reset={() => {
            if (editList) {
              setEditList();
              setFieldsValue();
            } else {
              setFieldsValue();
            }
          }}
          formatData={(data, editData) => {
            const payloadObj = {};
            InputFields.map((singleField) => {
              console.log(fieldsValue, editData, data);
              payloadObj[singleField.api] = data
                ? data[
                    singleField.name === "Custom Invoice"
                      ? "Custom InvoiceId"
                      : singleField.name === "Real Status"
                      ? "Real StatusId"
                      : singleField.name
                  ]
                : fieldsValue[
                    singleField.name === "Custom Invoice"
                      ? "Custom InvoiceId"
                      : singleField.name === "Real Status"
                      ? "Real StatusId"
                      : singleField.name
                  ];
              payloadObj["commercial_invoice"] = props.selectedCustomInvoice.id;
            });
            console.log(payloadObj);
            return payloadObj;
          }}
          module={"Payment"}
          selectedList={editList ? editList : false}
          editList={editList ? editList : false}
          handleClose={() => {
            setOpenDialog(false);
            setEditList(false);
          }}
          fetchAgain={(res, msg) => {
            setSuccessMsg(msg);
            loadPaymentList();
          }}
          postApi="arrival/payment-transaction/list"
          editApi="arrival/payment-transaction"
          textFieldStyle={{ width: "calc(33% - 20px)", margin: "10px" }}
          heightNew="calc(60vh - 100px)"
          maxHeight="calc(100vh - 100px)"
          widthNew="80%"
          inputArray={InputFields}
          postData={postData}
        />
      ) : (
        ""
      )}
      {error ? (
        <SnackbarComponent
          error={error}
          autoHideDuration={5000}
          // success={success}
          snackbarClose={() => {
            setError(false);
          }}
        />
      ) : (
        ""
      )}
      {loader === true ? (
        <StyledCircularProgress />
      ) : (
        <div className="width-100" style={{ minWidth: "100%" }}>
          <TableComponent
            // singleUnit={
            //   selectedCustomIn &&
            //   selectedCustomIn.custom_invoice_details &&
            //   selectedCustomIn.custom_invoice_details[0] &&
            //   selectedCustomIn.custom_invoice_details[0].sales_order_details &&
            //   selectedCustomIn.custom_invoice_details[0].sales_order_details[0] &&
            //   selectedCustomIn.custom_invoice_details[0].sales_order_details[0]
            //     .unit &&
            //   selectedCustomIn.custom_invoice_details[0].sales_order_details[0]
            //     .unit
            // }
            // weight={
            //   selectedCustomIn &&
            //   selectedCustomIn.net_weight &&
            //   selectedCustomIn.net_weight
            // }
            // changeUnit={(value, unit) => {
            //   return changeUnit(
            //     unit,
            //     selectedCustomIn &&
            //       selectedCustomIn.custom_invoice_details &&
            //       selectedCustomIn.custom_invoice_details[0] &&
            //       selectedCustomIn.custom_invoice_details[0]
            //         .sales_order_details &&
            //       selectedCustomIn.custom_invoice_details[0]
            //         .sales_order_details[0] &&
            //       selectedCustomIn.custom_invoice_details[0]
            //         .sales_order_details[0].unit &&
            //       selectedCustomIn.custom_invoice_details[0]
            //         .sales_order_details[0].unit,
            //     value
            //   );
            // }}
            // bottomBarheader={["Bags", "Total Quantity"]}
            nobottomBarAction={true}
            bottomBar={true}
            loading={loader}
            minHeight={"130px"}
            heightNew="100%"
            maxHeight="100%"
            widthNew="100%"
            module={"Payment"}
            actions={[]}
            noAddButton={true}
            header={[
              "Com. Inv. No.",
              "Inv Date",
              "Cust Inv.No.",
              "Cinv Date",
              "Bl No",
              "Pay Status",
              "Cont No",
              "Party Name",
              "Icd",
              "Dp",
              "Qty (Mt)",
              "Com",
              "Inv Amt",
              "Amt Rcvd",
              "Short",
              "Bill Id No",
              "Rate",
              "Real",
              "Action",
            ]}
            noSearchBar={true}
            {...props}
            tableList={
              paymentList && paymentList.length
                ? paymentList
                    .map((singlePayment) => {
                      console.log(singlePayment, "singlePayment");
                      const customeInvoice =
                        singlePayment.custom_invoice_details;
                      const commercialInvoice =
                        singlePayment.commercial_invoice_details;
                      return {
                        ...singlePayment,
                        "Com. Inv. No.": commercialInvoice.invoice_number,
                        "Inv Date": moment(
                          commercialInvoice.invoice_date
                        ).format("ddd, MMM Do YYYY"),
                        "Cust Inv.No.": customeInvoice.invoice_number,
                        "Cinv Date": moment(customeInvoice.invoice_date).format(
                          "ddd, MMM Do YYYY"
                        ),
                        "Bl No": customeInvoice.bl_number,
                        "Pay Status": singlePayment.payment_status,
                        "Cont No": customeInvoice.no_of_container,
                        "Party Name": customeInvoice.party_name,
                        Icd: customeInvoice.place_of_receipt_by_precarrier,
                        Dp: customeInvoice.discharge_port,
                        "Qty (Mt)": customeInvoice.total_item_quantity,
                        Com: commercialInvoice.commodity_name,
                        "Inv Amt": singlePayment.invoice_amount,
                        "Amt Rcvd": singlePayment.amount_received,
                        Short: singlePayment.short,
                        "Bill Id No": singlePayment.bill_no,
                        Rate: singlePayment.rate,
                        Real:
                          singlePayment.real_status === "CASH_RATE"
                            ? "CASH RATE"
                            : "FORWARD",
                        Action: () => (
                          <StyledButton
                            tooltip={"Edit"}
                            onClick={(e) => {
                              e.stopPropagation();
                              loadEditList(singlePayment.id);
                            }}
                            name={<EditIcon />}
                            width={"43px"}
                            maxWidth={"43px"}
                            onlyTextColor={primary}
                          />
                        ),
                      };
                    })
                    .sort((a, b) => b.id - a.id)
                : []
            }
            filterChild={false}
          />
        </div>
      )}
    </Paper>
  );
}

export default PaymentList;
