import React, { useRef, useState } from "react";

import { PDFExport } from "@progress/kendo-react-pdf";
import moment from "moment";
import "./pdf.css";
import ReactDOM from "react-dom";
import SvgPreview from "./SvgPreview";
import DownloadIcon from "@mui/icons-material/Download";
import { primary } from "../../styles/colors";
import { Dialog, Tooltip } from "@material-ui/core";
import { CompanyFullName } from "../../Defaults";
import { CssButton } from "./PurchaseOrdersComponent";
import { Transition } from "../SalesOrders/DetailsSo";
import { DialogContent, IconButton } from "@mui/material";
import { CrossIcon, Download } from "../../styles/Icons";
export const HDivider = (
  <div
    style={{
      minWidth: "100%",
      width: "100%",
      borderTop: "1px solid rgba(0, 0, 0, 2.25)",
    }}
  />
);
export const boldCss = { color: "black", fontWeight: "bold" };
const headerContent = (
  <div
    style={{
      padding: "5px 0",
      width: "calc(100% - 40px)",
      display: "flex",
      textTransform: "initial !important",
      margin: "0 10px",
    }}
  >
    <SvgPreview
      svg={"/SmileBots-log.png"}
      sx={{
        height: "40px",
        width: "60px",
      }}
    />
    <div
      style={{
        width: "calc(100% - 120px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRIGHT:"60px",
        textAlign:"justify"
      }}
    >
      <h2
        style={{
          ...boldCss,
          // textAlign: "end",
          textTransform: "uppercase",
          margin: "0 2px 0 0",
          fontSize: "0.9rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        textAlign:"justify"
        }}
      >
        {CompanyFullName}
      </h2>
      <div
        style={{
          fontSize: "0.8rem",
          fontWeight: "600",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        textAlign:"justify"
        }}
      >
        CIN No: U14200CT2007PTC020290
      </div>
    </div>
  </div>
);
const bottomBar = (
  <>
    {HDivider}
    <div style={{ width: "100%", fontWeight: "bold" }}>
      Registered Off : Behind Samrat Talkies, Civil Station Road, Raipur
      -492009,(C.G.) Ph:+91 771 2887453, 2524245
    </div>
    <div
      style={{
        width: "100%",
        // fontWeight: "bold",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
        Fax: +91 771 4032240, Email : sainath@srisainath.com Web:
        <span style={{ ...boldCss }}>www.srisainath.com</span>
      </div>
      <div>This is computer generated</div>
    </div>
    {HDivider}
  </>
);
export const PageTemplate = (props) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "15px",
          right: "15px",
          height: "50px",
          width: "calc(100% - 30px)",
          fontFamily: "Arial",
          borderBottom: "1.5px solid rgba(0, 0, 0, 2.25)",
        }}
      >
        {headerContent}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "15px",
          right: "15px",
          height: "40px",
          width: "calc(100% - 30px)",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            fontSize: "0.6rem",
            justifyContent: "flex-end",
          }}
        >
          {" "}
          {props && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "0.5rem",
              }}
            >{`Page:${props.pageNum}/${props.totalPages}`}</div>
          )}
          {bottomBar}
        </div>
      </div>
    </>
  );
};
function PurchaseOrderPdf(props) {
  const [download, setDownload] = useState(false);
  const [preview, setPreview] = useState(false);
  const {
    singlelistView,
    PaymentTermList,
    header,
    tableList,
    getSpecificationName,
    specifiationTypes,
  } = props;
  const contentArea = useRef(null);

  const handleExportWithFunction = async () => {
    if (contentArea.current) {
      await contentArea.current.save();

      await setDownload(false);
    }
  };
  const pdfData = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 8,
        borderRadius: "0",
        border: "1px solid rgba(0, 0, 0, 2.25)",
        height: "calc(100% - 2px)",
        width: "calc(100% - 2px)",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: "0",
            fontSize: "0.6rem",
          }}
        >
          {" "}
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1px",
              padding: "0",
            }}
          >
            <div
              style={{
                padding: "3px",
                fontSize: "0.9rem",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {"Purchase Order"}
            </div>
            <div
              style={{
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                padding: "0 3px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "0.6rem",
                }}
              >
                {`P.O No. ${
                  singlelistView && singlelistView.po_number
                    ? singlelistView.po_number
                    : ""
                }, `}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "0.6rem",
                }}
              >
                {singlelistView.contract_date
                  ? "Dated " +
                    moment(singlelistView.contract_date).format("DD.MM.YYYY")
                  : ""}
              </div>
            </div>
          </div>
          {HDivider}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr 1fr 3fr",
              gap: "1px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                padding: "0 2px",
                textAlign: "center",
                ...boldCss,
              }}
            >
              Supplier Name
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 2px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {singlelistView &&
                singlelistView.miller_details &&
                singlelistView.miller_details.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                padding: "0 2px",
                textAlign: "center",
              }}
            >
              Invoice To
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 2px",
              }}
            >
              {" "}
              SRI SAINATH INDUSTRY PRIVATE LIMITED, BEHIND SAMRAT TALKIES, CIVIL
              STATION ROAD, RAIPUR CHHATTISGARH. – 492009
            </div>
          </div>
          {HDivider}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr 1fr 3fr",
              gap: "1px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid rgba(0, 0, 0, 2.25)",

                textAlign: "center",
                ...boldCss,
              }}
            >
              Address
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",

                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div>
                {singlelistView &&
                  singlelistView.miller_details &&
                  singlelistView.miller_details.address}
              </div>
              {/* <div>{VDivider}</div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
              }}
            >
              Contact Person
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div style={{}}>{VDivider}</div> */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {singlelistView && singlelistView.miller_details.contact_person}
              </div>
            </div>
          </div>
          {HDivider}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr 1fr 3fr",
              gap: "1px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",

                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                textAlign: "center",
                ...boldCss,
              }}
            >
              GST No.
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",

                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <div>
                {singlelistView &&
                  singlelistView.miller_details &&
                  singlelistView.miller_details.gstin}
              </div>
              {/* <div>{VDivider}</div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",

                alignItems: "center",
                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
              }}
            >
              <div>GST No.</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div style={{}}>{VDivider}</div> */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                22AALCS6148P1ZR
              </div>
            </div>
          </div>
          {HDivider}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr 1fr 3fr",
              gap: "1px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",

                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                textAlign: "center",
                ...boldCss,
              }}
            >
              Delivery Condition
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",

                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                {`${
                  singlelistView && singlelistView.delivery_terms_extras
                    ? singlelistView.delivery_terms_extras
                    : ""
                }`}
              </div>
              {/* <div>{VDivider}</div> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",

                alignItems: "center",
                borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                textAlign: "center",
                ...boldCss,
              }}
            >
              Broker Name
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div style={{}}>{VDivider}</div> */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {singlelistView && singlelistView.broker_details.name}
              </div>
            </div>
          </div>
          {HDivider}
          {/* {HDivider}{" "} */}
          <div style={{ display: "flex", width: "100%" }}>
            {tableList && tableList.length > 0 ? (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 3fr 1fr 3fr",
                    gap: "1px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",

                      borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                      textAlign: "center",
                      ...boldCss,
                    }}
                  >
                    {header[0]}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      ...boldCss,

                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {header[1]}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",

                      alignItems: "center",
                      borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                      borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                      textAlign: "center",
                      ...boldCss,
                    }}
                  >
                    {header[2]}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "5fr 3fr",
                        width: "100%",
                        gap: "1px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",

                          alignItems: "center",
                          borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                          textAlign: "center",
                          ...boldCss,
                        }}
                      >
                        {" "}
                        {header[3]}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",

                          justifyContent: "center",
                          alignItems: "center",
                          ...boldCss,
                        }}
                      >
                        {header[4]}
                      </div>
                    </div>
                  </div>
                </div>
                {tableList.map((single, i2) => (
                  <>
                    {HDivider}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 3fr 1fr 3fr",
                        gap: "1px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",

                          borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                          textAlign: "center",
                        }}
                      >
                        {i2 + 1 < 10 ? `0${i2 + 1}` : i2 + 1}
                      </div>
                      <div
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",textAlign:"center"
                        }}
                      >
                        {/* <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              minHeight: "7px",
                            }}
                          > */}
                        {/* {single["Brand"] === "Unknown" ||
                            single["Brand"] === "unknown"
                              ? ""
                              : `${single["Brand"] ? single["Brand"] : ""}, ${
                                  single["Item Category"]
                                    ? single["Item Category"]
                                    : ""
                                }, ${
                                  single["Item Type"] ? single["Item Type"] : ""
                                } %`} */}
                        {/* </div> */}
                        {console.log(single, single.Specifications)}
                        {
                          single.Specifications &&
                            single.Specifications.length > 0 &&
                            single.Specifications.map((sp) => {
                              const ss = sp.nameId ?? sp.name;
                              const filterS = specifiationTypes.find(
                                (st) => st.id === ss
                              );
                              return `${
                                  filterS
                                    ? `${filterS.text}`
                                    : sp.name
                                } ${
                                  filterS
                                    ? `${sp.value ?? ""} ${
                                        filterS.unit
                                          ? filterS.unit.toUpperCase()
                                          : ""
                                      }`
                                    : sp.value
                                } ${getSpecificationName(sp.condition)} `
                              
                            }).join(", ")
                        }
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",

                          alignItems: "center",
                          borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                          borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                          textAlign: "center",
                        }}
                      >
                        {singlelistView && singlelistView.unit
                          ? `${
                              single["Total Item Quantity"]
                                ? single["Total Item Quantity"]
                                : "-"
                            } ${singlelistView.unit}`
                          : ""}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "5fr 3fr",
                            width: "100%",
                            gap: "1px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",

                              alignItems: "center",
                              borderRight: "1px solid rgba(0, 0, 0, 2.25)",
                              textAlign: "center",
                            }}
                          >
                            {single["Contract Rate"]
                              ? ` ${Number(single["Contract Rate"]).toFixed(
                                  2
                                )} / ${
                                  singlelistView && singlelistView.unit
                                    ? singlelistView.unit
                                    : ""
                                }`
                              : ""}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",

                              justifyContent: "center",
                              alignItems: "center",
                              // ...boldCss,
                            }}
                          >
                            {"NIL"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
          {HDivider}
          <div
            style={{
              width: "calc(100% - 6px)",
              margin: "0 3px",
            }}
          >
            <span
              style={{
                textAlign: "justify",
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                  
                  letterSpacing: "0.0001rem",
                }}
              >
                Acceptance of Purchase Order:
              </span>
              {` ${
                singlelistView && singlelistView.acceptance_of_purchase_order
                  ? singlelistView.acceptance_of_purchase_order
                  : ""
              }`}
            </span>
          </div>
          {HDivider}
          <div
            style={{
              width: "100%",
              padding: "0 3px",
            }}
          >
            <span
              style={{
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                  
                  letterSpacing: "0.0001rem",
                }}
              >
                Eway Bill:
              </span>
              {` ${
                singlelistView && singlelistView.eway_bill
                  ? singlelistView.eway_bill
                  : ""
              }`}
            </span>
          </div>
          {HDivider}
          <span
            style={{
              width: "100%",
              padding: "0 3px",
              
              letterSpacing: "0.0001rem",
            }}
          >
            <span
              style={{
                ...boldCss,
                
                letterSpacing: "0.0001rem",
              }}
            >
              Payment Terms:
            </span>{" "}
            {"Payment shall be made by RTGS within " +
              `${
                singlelistView && singlelistView.payment_terms
                  ? PaymentTermList &&
                    PaymentTermList.length > 0 &&
                    PaymentTermList.filter(
                      (sPT) => sPT.id === singlelistView.payment_terms
                    ) &&
                    PaymentTermList.filter(
                      (sPT) => sPT.id === singlelistView.payment_terms
                    )[0] &&
                    PaymentTermList.filter(
                      (sPT) => sPT.id === singlelistView.payment_terms
                    )[0].days_range
                  : ""
              }` +
              " WD after receipt of material in our CHA's godown/CFS."}
          </span>
          {HDivider}
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              padding: "0",
            }}
          >
            <span
              style={{
                padding: "0 3px",
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                }}
              >
                Packing Condition:
              </span>{" "}
              {tableList && tableList.length > 0
                ? tableList.map((tableData) =>
                    tableData[`packagageArrUsed`] &&
                    tableData[`packagageArrUsed`].length > 0
                      ? tableData[`packagageArrUsed`].map((single, i) => (
                          <>{`In ${single.size} ${single.unit} Net. new ${
                            single.bag_type
                          } bags${
                            tableData[`packagageArrUsed`].length - 1 === i
                              ? "."
                              : ", "
                          }`}</>
                        ))
                      : ""
                  )
                : ""}
            </span>
            <span
              style={{
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                padding: "0 3px",
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                }}
              >
                Delivery Condition:
              </span>
              {` ` +
                `${
                  singlelistView &&
                  singlelistView.contract_type_details.contract_type
                }` +
                " delivered in buyer’s nominated CHA’s warehouse in " +
                `${
                  singlelistView && singlelistView.delivery_terms_extras
                    ? singlelistView.delivery_terms_extras
                    : ""
                } ` +
                `by trucks.`}
            </span>
          </div>
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <span
              style={{
                padding: "0 3px",
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                }}
              >
                Loading:
              </span>{" "}
              {` ${
                singlelistView && singlelistView.loading
                  ? singlelistView.loading
                  : ""
              }`}
            </span>
            <span
              style={{
                borderLeft: "1px solid rgba(0, 0, 0, 2.25)",
                padding: "0 3px",
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                  
                  letterSpacing: "0.0001rem",
                }}
              >
                Contract Cancellation:
              </span>{" "}
              {`${
                singlelistView && singlelistView.contract_cancellation
                  ? singlelistView.contract_cancellation
                  : ""
              }`}
            </span>
          </div>
          {HDivider}
          <div
            style={{
              width: "100%",
              padding: "0 3px",
            }}
          >
            {" "}
            <span
              style={{
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                  
                  letterSpacing: "0.0001rem",
                }}
              >
                Quality:{" "}
              </span>{" "}
              Quality before loading shall be approved by buyer nominated
              independent surveyor. Buyer can also depute in house inspectors in
              the rice mill. No truck to leave rice mill unless quality/weight
              approved by the surveyor.
            </span>
          </div>
          {HDivider}
          <div
            style={{
              width: "100%",
              padding: "0 3px",
            }}
          >
            <span
              style={{
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                  
                  letterSpacing: "0.0001rem",
                }}
              >
                Weighment Condition:
              </span>{" "}
              Weight/Quality shall be final at CHA’s weighbridge.
            </span>
          </div>
          {HDivider}
          <div
            style={{
              width: "100%",
              padding: "0 3px",
            }}
          >
            <span
              style={{
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                }}
              >
                Jurisdiction:
              </span>{" "}
              All disputes subject to Raipur Chhattisgarh jurisdiction.
            </span>
          </div>
          {HDivider}
          <div
            style={{
              minWidth: "calc(100% - 6px)",
              display: "flex",
              padding: "0 3px",
              whiteSpace: "normal",
              textTransform: "initial !important",
            }}
          >
            <span
              style={{
                textAlign: "justify",
                
                letterSpacing: "0.0001rem",
              }}
            >
              <span
                style={{
                  ...boldCss,
                }}
              >
                Other conditions:
              </span>
              The Seller assures that there is no lien, charge or encumbrance of
              any nature created in respect of the Materials sold by the Seller
              and assures that a clear, good and valid title shall be conveyed
              to Buyer. Seller also confirms that there is no restriction
              whatsoever by means of any statute, regulations, contracts,
              covenants or the like, restricting the Seller from performing any
              of its obligations under this Purchase Order. Seller shall be
              primarily responsible to prove all the time to the Buyer for
              providing of all necessary documents required by any Government
              agency to substantiate the legal ownership of the cargo as per any
              regulation. Seller shall also undertake that he shall provide all
              the necessary support to the Buyer to handle any issue come up
              with respect to quality & legal title of the material either
              before or after export of the cargo. Seller also confirms that the
              Material supplied under this agreement is not meant for Public
              Distribution System (PDS) or diverted from PDS. Seller confirms
              they are not Fair Price Shop or Stage I contractor of the
              District. Seller abides by the Essential Commodities Act and its
              Provision as contemplated under Sec 6A of the Act. The Seller
              shall indemnify the buyer for any cost, expenses, penalties that
              may be incurred/liable to be paid by buyer in respect of the title
              of the goods sold by buyer. Cargo should be fresh milled from the
              rice mill & there should not be any live insect or weevil.
            </span>
          </div>
          {HDivider}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flex: 8,
            justifyContent: "space-between",
            fontSize: "0.6rem",
          }}
        >
          <div
            style={{
              padding: "0 5px",
              display: "flex",
              minHeight: "100%",
              height: "100%",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div style={{ padding: "0", width: "100%", display: "flex" }}>
              For SRI SAINATH INDUSTRY PVT LTD
            </div>
            <div
              style={{
                padding: "10px 0 0 0",
                width: "100%",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div> Authorized Signatory</div> <div> Broker</div>
              <div> Accepted - Seller ( with sign and seal )</div>
            </div>
          </div>
        </div>
        {/* </PdfLayout> */}
      </div>
    </div>
  );
  return (
    <>
      {preview && (
        <Dialog
          ref={(e) => {
            try {
              const target = ReactDOM.findDOMNode(e).children[2].children[0];
              target.style.borderRadius = "0px";
              target.style.maxHeight = "calc(100vh - 62px)";
              target.style.minHeight = "calc(100vh - 62px)";
              target.style.height = "calc(100vh - 62px)";
              target.style.minWidth = "fit-content";
              target.style.maxWidth = "calc(100% - 62px)";
              target.style.margin = "32px";
            } catch (e) {}
          }}
          open={true}
          onClose={() => {
            setDownload(false);
            setPreview(false);
          }}
          TransitionComponent={Transition}
        >
          <Tooltip title="Download" arrow placement="top">
            <IconButton
              onClick={() => {
                setDownload(true);
                handleExportWithFunction();
              }}
              style={{
                padding: "5px",
                borderRadius: "5px",
                position: "absolute",
                right: "48px",
                top: "8px",
                color: primary,
              }}
            >
              <Download height="18px" />
            </IconButton>
          </Tooltip>
          <Tooltip title="close" arrow placement="top">
            <IconButton
              onClick={() => {
                setDownload(false);
                setPreview(false);
              }}
              style={{
                padding: "5px",
                borderRadius: "5px",
                position: "absolute",
                right: "24px",
                top: "10px",
              }}
            >
              <CrossIcon strokeWidth="2" color={primary} />
            </IconButton>
          </Tooltip>
          <DialogContent
            style={{
              height: "calc(100% - 20px)",
              justifyContent: "center",
              display: "flex",
              padding: "10px",
            }}
          >
            <div
              style={{
                textTransform: "initial !important",
                width: "60%",
                height: "fit-content",
                padding: "10px",
                position: "relative",
                boxShadow:
                  "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
              }}
            >
              <div
                style={{
                  height: "50px",
                  width: "calc(100% - 10px)",
                  fontFamily: "Poppins",
                  borderBottom: "1.5px solid rgba(0, 0, 0, 2.25)",
                }}
              >
                {headerContent}
              </div>
              <div style={{ margin: "10px", fontFamily: "Poppins" }}>
                <div
                  style={{
                    height: "fit-content",
                    minHeight: "fit-content",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    color: "rgba(0, 0, 0, 0.84)",
                  }}
                >
                  {" "}
                  {pdfData}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "0.6rem",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  fontFamily: "Poppins",
                }}
              >
                {" "}
                {bottomBar}
              </div>
              <div
                className="pdf"
                style={{
                  height: "0",
                  width: "0",
                  overflow: "hidden",
                  textTransform: "initial !important",
                  fontFamily: "Arial",
                }}
              >
                {" "}
                <PDFExport
                  ref={contentArea}
                  // renderAnnotationLayer={false}
                  // renderTextLayer={false}
                  keepTogether="p"
                  paperSize="A4"
                  // margin="2cm"
                  forcePageBreak=".page-break"
                  fileName={`${singlelistView && singlelistView.po_number}.pdf`}
                  // allPages={true}

                  margin={{
                    bottom: 50,
                    left: 25,
                    right: 25,
                    top: 65,
                  }}
                  pageTemplate={PageTemplate}
                >
                  <div
                    style={{
                      height: "727px",
                      minHeight: "727px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      color: "rgba(0, 0, 0, 0.84)",
                    }}
                  >
                    {pdfData}
                  </div>
                </PDFExport>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <CssButton
        onClick={() => {
          setPreview(true);
        }}
        style={{
          background: "rgb(241 154 48)",
          color: "white",
          marginRight: "10px",
          textTransform: "initial !important",
        }}
        size="small"
      >
        Download <DownloadIcon fontSize="small" />
      </CssButton>
    </>
  );
}

export default PurchaseOrderPdf;
